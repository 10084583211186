import { gql } from '@apollo/client/core'

/**
 * Use the multipass token to get an access token.
 * This is a frontend-only request
 */

export const GET_SHOPIFY_ACCESS_TOKEN = gql`
    mutation CustomeAccessToken($multipassToken: String!) {
        customerAccessTokenCreateWithMultipass(multipassToken: $multipassToken) {
            customerAccessToken {
                accessToken
                expiresAt
            }
            customerUserErrors {
                code
                field
                message
            }
        }
    }
`

// Use this query in the front end
export const CLIENT_SIDE_USER_GRAPHQL_QUERY = gql`
    query GetUser {
        user {
            id
            first_name
            last_name
            email
            phone_number
            nickname
            date_of_birth
        }
    }
`
