import * as React from 'react'
import { IconButton } from 'components/buttons/button'
import globalManifest from 'data/global-manifest.json'
import CrossIcon from 'icons/CrossIcon'

interface IProps {
    onClose: () => void
    title?: string
    closeButton?: React.ReactNode
}

type TSidebarHeaderButtonProps = Pick<IProps, 'onClose'> & {
    icon?: React.ReactNode
}

const { resources: globalUIResources } = globalManifest

export function SidebarHeader({ onClose, title, closeButton }: IProps) {
    closeButton = closeButton ?? <SidebarHeaderCloseButton onClose={onClose} />

    return (
        <div className="flex justify-between space-x-2 w-full items-center py-2.5">
            {title && <div className="text-xl">{title}</div>}
            {closeButton}
        </div>
    )
}

export function SidebarHeaderCloseButton({ onClose, icon }: TSidebarHeaderButtonProps) {
    icon = icon ?? <CrossIcon height="8" width="8" />

    return (
        <IconButton
            onClick={onClose}
            className="h-6 w-6 p-0 flex justify-center items-center border border-gray rounded-full ml-auto"
            label={globalUIResources.closeIconButtonLabel.value}
            data-cy="close-button"
        >
            {icon}
        </IconButton>
    )
}
