import * as React from 'react'
import { ILink } from 'interfaces/link'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import Link from 'next/link'
import twConfig from 'tailwind-light-config'

const { colors, screens } = twConfig

interface IProps {
    link: ILink
    onClick?: () => void
}

const discountLiteralInContentfulText = '{0}'

const descriptionRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="text-xs font-700 leading-140%">{children}</p>,
}

const lgBreakPoint = screens.lg

export const LinkBlock = ({ link, onClick }: IProps): React.ReactElement => {
    if (!link) {
        return null
    }

    const { name, href, description, picture, associatedColors: associatedColorsFromLink } = link

    const { mobileImagesCollection, desktopImagesCollection, associatedColors } = picture ?? {}

    const mobileImage = mobileImagesCollection?.items?.[0]
    const desktopImage = desktopImagesCollection?.items?.[0]

    const hasDescription = !!description

    let textColor
    if (associatedColorsFromLink && associatedColorsFromLink.length > 1) {
        textColor = associatedColorsFromLink.split(',')[1]
    }

    return (
        <Link
            href={href}
            onClick={() => onClick?.()}
            className="rounded-2lg overflow-hidden relative flex bg-gray-lighter ring-gray-darker can-hover:hover:ring-1 can-hover:hover:bg-[color:var(--hoverBackground)] transition-all duration-300 ease-out h-full"
            style={{
                ['--hoverBackground' as string]: associatedColors ?? colors.gray.lighter,
            }}
        >
            {(mobileImage || desktopImage) && (
                <picture className="w-full">
                    {desktopImage?.url && (
                        <>
                            {/* svg */}
                            {desktopImage.url.endsWith('.svg') && (
                                <source
                                    media={`(min-width: ${lgBreakPoint})`}
                                    type="image/svg+xml"
                                    srcSet={desktopImage.url}
                                />
                            )}
                            {/* webp */}
                            <source
                                media={`(min-width: ${lgBreakPoint})`}
                                type="image/webp"
                                srcSet={`
                                        ${desktopImage.url}?fm=webp&w=400 400w,
                                        ${desktopImage.url}?fm=webp&w=800 800w`}
                            />
                            {/*jpeg */}
                            <source
                                media={`(min-width: ${lgBreakPoint})`}
                                type="image/jpeg"
                                srcSet={`
                                        ${desktopImage.url}?fm=jpg&w=400 400w,
                                        ${desktopImage.url}?fm=jpg&w=800 800w`}
                            />
                        </>
                    )}

                    {mobileImage?.url && (
                        <>
                            {/* svg */}
                            {mobileImage.url.endsWith('.svg') && (
                                <source type="image/svg+xml" srcSet={mobileImage.url} />
                            )}
                            {/* webp */}
                            <source
                                type="image/webp"
                                srcSet={`
                                    ${mobileImage.url}?fm=webp&w=300 300w,
                                    ${mobileImage.url}?fm=webp&w=500 500w
                                `}
                            />
                            {/*jpeg */}
                            <source
                                type="image/jpeg"
                                srcSet={`
                                    ${mobileImage.url}?fm=jpg&w=300 300w,
                                    ${mobileImage.url}?fm=jpg&w=500 500w
                                `}
                            />
                        </>
                    )}

                    <img
                        src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=400'}
                        alt={
                            mobileImage?.title ||
                            desktopImage?.title ||
                            mobileImage?.description ||
                            desktopImage?.description
                        }
                        width={mobileImage?.width || desktopImage?.width}
                        height={mobileImage?.height || desktopImage?.height}
                        className="h-full w-full object-cover max-h-96 lg:max-h-[none]"
                    />
                </picture>
            )}

            {hasDescription ? (
                <div className="p-2.5">
                    <span className="text-xbase font-bold leading-140%">{name}</span>
                    <hr className="mt-2.5 border-black" />
                    <ContentfulRichText
                        content={description.json}
                        renderNode={descriptionRenderNode}
                        className="mt-2.5"
                    />
                </div>
            ) : (
                <div className="absolute bottom-2.5 left-2">
                    {name?.includes(discountLiteralInContentfulText) ? (
                        <div className="text-xbase font-bold flex space-x-1 items-center" style={{ color: textColor }}>
                            <span className={`${textColor ? 'text-shadow' : ''}`}>
                                {name?.replace(discountLiteralInContentfulText, '')}
                            </span>
                        </div>
                    ) : (
                        <span
                            // not a solid logic for text-shadow, but assuming text-color will
                            // be a lighter color, using text shadow to pop out light text over image
                            className={`text-xbase font-bold ${textColor ? 'text-shadow' : ''}`}
                            style={{ color: textColor }}
                        >
                            {name}
                        </span>
                    )}
                </div>
            )}
        </Link>
    )
}
