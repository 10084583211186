import * as React from 'react'
import MinusIcon from 'icons/minus'
import PlusIcon from 'icons/plus'
import styles from './style.module.css'
import { IUiResources } from 'interfaces/ui-resource'
import { IKitLineItem } from 'lib/cart'
import { useOTCCartContext } from 'context/otc-cart-context'
import Link from 'next/link'
import { useCartSidebarContext } from 'context/cart-sidebar-context'
import { FIRST_NUMBER_IDENTIFIER_REGEXP, JAR_PILL_VARIANT_REGEXP } from 'lib/util/sku-querying'
import { colors } from 'theme.cjs'
import CartLineItem from './cart-line-item'

interface Props {
    uiResources: IUiResources
    kitLineItem: IKitLineItem
}

export function CartKitLineItem({ uiResources, kitLineItem }: Props): React.ReactElement {
    const { addKitQuantity, subtractKitQuantity, removeKitLineItem, isFetchingDetailsOfCartItems } = useOTCCartContext()
    const { closeSidebar: closeCart } = useCartSidebarContext()

    const [showProducts, setShowProducts] = React.useState(true)

    const totalPriceOfAllKitMedicines = kitLineItem.price * kitLineItem.quantity

    const currency: string = kitLineItem?.products?.[0]?.shopifyProduct?.variants?.edges?.find(({ node }) =>
        JAR_PILL_VARIANT_REGEXP.test(node?.sku),
    )?.node?.price?.currencyCode

    const areAllMedicinesAvailable: boolean = kitLineItem?.products?.every((medicine) => {
        const jarVariant = medicine?.shopifyProduct?.variants?.edges?.find((variant) =>
            JAR_PILL_VARIANT_REGEXP.test(variant?.node?.sku),
        )

        return jarVariant?.node?.availableForSale
    })

    let totalMedicinesInKit = 0

    kitLineItem.products.forEach((p) => {
        totalMedicinesInKit += p.quantity
    })

    return (
        <CartLineItem
            title={
                <>
                    <Link
                        href={`/products/${kitLineItem.slug}`}
                        className="text-sm font-bold block truncate"
                        onClick={() => closeCart()}
                    >
                        {kitLineItem.productName}
                    </Link>

                    <div className="text-gray text-xs tracking-wide mt-1">
                        {totalMedicinesInKit}{' '}
                        {totalMedicinesInKit === 1
                            ? uiResources['productSingularLabel'].value
                            : uiResources['productPluralLabel'].value}
                    </div>

                    {/* what's inside section */}
                    <div className="mt-1">
                        <button
                            data-cy="toggle-kit-medicine-list"
                            className={`text-xs text-left tracking-wide flex items-center border-b border-gray-lighter ${
                                showProducts ? 'w-full' : ''
                            }`}
                            onClick={() => setShowProducts(!showProducts)}
                        >
                            {uiResources['seeWhatsInsideLabel'].value}
                            <div className="ml-2">
                                {showProducts ? (
                                    <MinusIcon fill={colors.mineshaft} width={5} height={5} />
                                ) : (
                                    <PlusIcon fill={colors.mineshaft} width={5} height={5} />
                                )}
                            </div>
                        </button>
                        {/* medicines in kit */}
                        <ul
                            className={`overflow-hidden duration-75 transition-all ${
                                showProducts ? 'max-h-infinity mb-2.5' : 'max-h-0 mb-0'
                            }`}
                        >
                            {kitLineItem?.products?.map((product) => {
                                const jarVariant = product?.shopifyProduct?.variants?.edges?.find((variant) =>
                                    JAR_PILL_VARIANT_REGEXP.test(variant?.node?.sku),
                                )

                                const pillsQuantity = Number(
                                    FIRST_NUMBER_IDENTIFIER_REGEXP.exec(jarVariant?.node?.sku || '')?.[0],
                                )

                                return (
                                    <li
                                        data-cy="kit-medicine"
                                        className={`mt-1 text-xs truncate ${
                                            jarVariant?.node?.availableForSale ? '' : 'opacity-50'
                                        }`}
                                        key={product?.sys?.id}
                                    >
                                        {product.quantity}x {product.cabinetDrugName}{' '}
                                        <span className="text-gray">
                                            {pillsQuantity}{' '}
                                            {pillsQuantity === 1 ? product.pillType : product.pillTypePlural}
                                        </span>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </>
            }
            image={kitLineItem.thumbnail}
            price={totalPriceOfAllKitMedicines}
            currency={currency}
            loading={isFetchingDetailsOfCartItems}
            body={
                areAllMedicinesAvailable && (
                    <div className="py-0.5 px-3 border border-gray rounded-full flex justify-center">
                        <button
                            data-cy="subtract-quantity"
                            disabled={kitLineItem.quantity < 2}
                            className="disabled:opacity-20 text"
                            onClick={() => subtractKitQuantity(1, kitLineItem.id)}
                            aria-label={uiResources['ariaLabelSubtractQuantity'].value as string}
                        >
                            <MinusIcon fill={colors.mineshaft} />
                        </button>
                        <span
                            data-cy="cart-item-quantity"
                            className={`mx-0.5 bg-transparent text-center text-xs flex items-center justify-center ${styles['cart-item-quantity']}`}
                        >
                            {kitLineItem.quantity}
                        </span>
                        <button
                            data-cy="add-quantity"
                            className="focus"
                            onClick={() => addKitQuantity(1, kitLineItem.id)}
                            aria-label={uiResources['ariaLabelAddQuantity'].value as string}
                        >
                            <PlusIcon fill={colors.mineshaft} />
                        </button>
                    </div>
                )
            }
            onRemove={() => removeKitLineItem(kitLineItem.id)}
            isAvailable={areAllMedicinesAvailable}
        />
    )
}
