import * as React from 'react'
import { useRXCartContext } from 'context/rx-cart-context'
import { CartRxLineItem } from './cart-rx-line-item'
import { IUiResources } from 'interfaces/ui-resource'
import Loader from 'components/loading/loader'
import { getFormattedNumberWithCurrency } from 'lib/util/currency'
import { wordInsideCurlyBracketsRegexp } from 'lib/util/regexps'
import globalManifest from 'data/global-manifest.json'
import Checkbox from 'components/checkbox/checkbox'
import ContentfulRichText from 'components/rich-text'
import { RenderNode } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { notAvailableText } from 'constants/content'
import styles from './style.module.css'
import { DISCOUNTS } from 'enum/discounts'

interface IProps {
    uiResources: IUiResources
}

const { commonCopy: globalCommonCopy } = globalManifest
const rxUserInfoDisclosureAgreement = globalCommonCopy['rxUserInfoDisclosureAgreement']
const rxNotCRCAgreement = globalCommonCopy['rxNotCRCAgreement']

export const RXCart = ({ uiResources }: IProps): React.ReactElement => {
    const { lineItems, invoiceGenerationState, invoice, generateInvoice, lineItemToHighlightId } = useRXCartContext()
    const [isCRCAgreed, setIsCRCAgreed] = React.useState(true)
    const [isUserInfoDisclosureAgreed, setIsUserInfoDisclosureAgreed] = React.useState(true)

    const listElRef = React.useRef<HTMLUListElement>()

    React.useEffect(() => {
        if (lineItemToHighlightId) {
            const index = lineItems.findIndex((lineItem) => lineItem.id === lineItemToHighlightId)

            const listItem = listElRef.current.children[index]

            listItem.scrollIntoView()
        }
    }, [lineItemToHighlightId, lineItems])

    const membershipDiscount = invoice?.line_items?.reduce((sum, lt) => {
        const memDis =
            lt.applied_discount?.title === DISCOUNTS.MEMBERSHIP_DISCOUNT ? Number(lt.applied_discount.amount) : 0
        return sum + memDis
    }, 0)

    const subTotal = invoice?.subtotal_price
        ? getFormattedNumberWithCurrency(Number(invoice.subtotal_price) + membershipDiscount, invoice.currency)
        : notAvailableText

    const total = invoice?.total_price
        ? getFormattedNumberWithCurrency(Number(invoice.total_price), invoice.currency)
        : notAvailableText

    // assumption: if membership discount has been applied then a member
    const hasMembership = membershipDiscount > 0
    const hasFreeShipping = hasMembership

    const isGeneratingInvoice = invoiceGenerationState === 'generating'

    const buttonLabel = uiResources['rxCartGenerationErrorLabel'].value
        .match(wordInsideCurlyBracketsRegexp)?.[0]
        ?.replace(/{|\}/g, '')

    const isCheckoutDisabled =
        !isCRCAgreed || !isUserInfoDisclosureAgreed || !invoice?.invoice_url || isGeneratingInvoice

    return (
        <div className="h-full flex flex-col">
            {invoiceGenerationState === 'error' && (
                <div className="p-2.5 bg-error-medium/80 text-xsm">
                    {uiResources['rxCartGenerationErrorLabel'].value.replace(wordInsideCurlyBracketsRegexp, '')}
                    {buttonLabel && (
                        <button className="underline" onClick={generateInvoice}>
                            {buttonLabel}
                        </button>
                    )}
                </div>
            )}
            <ul className={styles['cart-list']} ref={listElRef} data-cy="rx-cart-line-items">
                {lineItems.map((lineItem) => (
                    <li
                        key={lineItem.id}
                        className={`${styles['cart-list-item']} ${
                            lineItem.id === lineItemToHighlightId ? styles['cart-list-item--highlight'] : ''
                        }`}
                    >
                        <CartRxLineItem lineItem={lineItem} uiResources={uiResources} />
                    </li>
                ))}
            </ul>

            <div className="flex flex-col justify-center items-start gap-y-3 p-5">
                <Checkbox
                    label={
                        <ContentfulRichText
                            // @ts-ignore
                            content={rxNotCRCAgreement.value.json}
                            renderNode={consentCheckboxLabelRenderNode}
                        />
                    }
                    onChange={(e) => setIsCRCAgreed(e.target.checked)}
                    checked={isCRCAgreed}
                />
                <Checkbox
                    label={
                        <ContentfulRichText
                            // @ts-ignore
                            content={rxUserInfoDisclosureAgreement.value.json}
                            renderNode={consentCheckboxLabelRenderNode}
                        />
                    }
                    onChange={(e) => setIsUserInfoDisclosureAgreed(e.target.checked)}
                    checked={isUserInfoDisclosureAgreed}
                />
            </div>

            {/* cart footer */}
            <div className="border-t border-gray-medium">
                {/* pricing summary */}
                <div className="p-5 pb-2.5 flex flex-col space-y-4">
                    <div className="flex justify-between space-x-2">
                        <span className="text-sm leading-140% tracking-7">
                            {uiResources['shoppingCartSubtotal'].value}
                        </span>
                        {isGeneratingInvoice ? (
                            <div className="animate-pulse w-12 h-5 bg-gray-medium rounded-5 ml-auto" />
                        ) : (
                            <span data-cy="subtotal">{subTotal}</span>
                        )}
                    </div>
                    {membershipDiscount > 0 && (
                        <div className="flex justify-between space-x-2">
                            <span className="text-sm leading-140% tracking-7">
                                {uiResources['cartMembershipDiscountLabel'].value}
                            </span>
                            {isGeneratingInvoice ? (
                                <div className="animate-pulse w-12 h-5 bg-gray-medium rounded-5 ml-auto" />
                            ) : (
                                <span data-cy="membership-discount">
                                    &ndash;{getFormattedNumberWithCurrency(membershipDiscount)}
                                </span>
                            )}
                        </div>
                    )}
                    {hasFreeShipping && (
                        <div data-cy="free-shipping-block" className="flex justify-between space-x-2">
                            <span className="text-sm leading-140% tracking-7">
                                {uiResources['shippingLabel'].value}
                            </span>
                            {isGeneratingInvoice ? (
                                <div className="animate-pulse w-12 h-5 bg-gray-medium rounded-5 ml-auto" />
                            ) : (
                                <span>{uiResources['freeShippingWithMembership'].value}</span>
                            )}
                        </div>
                    )}
                    <div className="flex justify-between space-x-2 font-bold">
                        <span className="text-sm leading-140% tracking-7">{uiResources['orderTotalLabel'].value}</span>
                        {isGeneratingInvoice ? (
                            <div className="animate-pulse w-12 h-5 bg-gray-medium rounded-5 ml-auto" />
                        ) : (
                            <span data-cy="total">{total}</span>
                        )}
                    </div>
                    <div className="text-xs text-gray-darker text-right leading-3 tracking-[0.12px]">
                        *{uiResources['cartTotalDisclaimer'].value}
                    </div>
                </div>

                <div className="p-5 border-t border-gray-medium">
                    <a
                        href={isGeneratingInvoice || isCheckoutDisabled ? '' : (invoice?.invoice_url as string)}
                        className={`w-full button button-primary flex justify-center ${
                            isCheckoutDisabled ? 'cursor-not-allowed opacity-60' : ''
                        }`}
                        onClick={isCheckoutDisabled ? (e) => e.preventDefault() : null}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {isGeneratingInvoice ? <Loader /> : uiResources['rxCartCheckoutButtonLabel'].value}
                    </a>
                </div>
            </div>
        </div>
    )
}

const consentCheckboxLabelRenderNode: RenderNode = {
    [BLOCKS.PARAGRAPH]: (_, children) => <p className="text-sm leading-140%">{children}</p>,
}
