import * as React from 'react'
import OTCCart from './otc-cart'
import { RXCart } from './rx-cart'
import { IUiResources } from 'interfaces/ui-resource'
import { useCartSidebarContext } from 'context/cart-sidebar-context'
import { useRXCartContext } from 'context/rx-cart-context'
import styles from './style.module.css'
import ProgressBar from 'components/progress-bar'
import useOTCCartCalculation from 'hooks/useOTCCartCalculation'
import globalManifest from 'data/global-manifest.json'
import { colors } from 'theme'

interface IProps {
    uiResources: IUiResources
}

const { resources: globalUIResources } = globalManifest

const shippingThreshold = Number(globalUIResources['shippingThreshold'].value)

export function Cart({ uiResources }: IProps): React.ReactElement {
    const { activeCart, setActiveCart } = useCartSidebarContext()

    const { total: otcCartTotal } = useOTCCartCalculation()
    const { invoice } = useRXCartContext()

    const rxCartTotal = Number(invoice?.total_price ?? 0)
    const total = activeCart === 'otc' ? otcCartTotal : rxCartTotal

    return (
        <>
            <ul className={`flex bg-white p-2 pb-0 ${styles['cart-tabs-background']}`}>
                <TabListElement
                    isActive={activeCart === 'otc'}
                    onClick={() => setActiveCart('otc')}
                    label={uiResources['otcCartTabLabel'].value}
                />
                <TabListElement
                    isActive={activeCart === 'rx'}
                    onClick={() => setActiveCart('rx')}
                    label={uiResources['rxCartTabLabel'].value}
                />
            </ul>

            <div className="h-0 flex-grow flex flex-col">
                <div className="flex flex-col px-5 lg:px-7.5 py-5">
                    <span className="text-[10px] font-bold leading-[100%] tracking-wide text-center">
                        {total >= shippingThreshold
                            ? globalUIResources['cartHeaderFreeShippingAppliedMessage'].value
                            : globalUIResources['cartHeaderFreeShippingRemainderLabel'].value.replace(
                                  '{0}',
                                  (shippingThreshold - total).toFixed(2).toString(),
                              )}
                    </span>
                    {/* progress/remainder indicator */}
                    <ProgressBar
                        className="!h-[5px] mt-2 rounded-md overflow-hidden"
                        progress={(total / shippingThreshold) * 100}
                        progressColor={colors.mineshaft}
                        progressTrackColor={colors.mediumGray}
                        animate
                    />
                </div>
                <div className={`h-0 flex-grow ${activeCart === 'otc' ? '' : 'hidden'}`}>
                    <OTCCart uiResources={uiResources} />
                </div>
                <div className={`h-0 flex-grow ${activeCart === 'rx' ? '' : 'hidden'}`}>
                    <RXCart uiResources={uiResources} />
                </div>
            </div>
        </>
    )
}

function TabListElement({ isActive, onClick, label }: { isActive: boolean; onClick: () => void; label: string }) {
    return (
        <li className={` w-1/2 p-2 pb-3.5 ${isActive ? 'bg-white rounded-t-md' : ''}`}>
            <button className="w-full text-center transition-colors font-bold text-sm leading-140%" onClick={onClick}>
                {label}
            </button>
        </li>
    )
}
