import { useEffect, useState } from 'react'
import { useCustomerContext } from 'context/customer-context'
import { useFeature } from '@growthbook/growthbook-react'

export default function GorgiasChatScript(): null {
    const customer = useCustomerContext()
    const [loading, setLoading] = useState(null)
    const { value: warmWelcomeEnabled } = useFeature('warm-welcome')

    // if warm welcome is on, disable Gorgias
    useEffect(() => {
        if (warmWelcomeEnabled && !loading) {
            const gorgiasChatContainerDOMNode: HTMLElement | null = document.querySelector('#gorgias-chat-container')
            if (gorgiasChatContainerDOMNode !== null) gorgiasChatContainerDOMNode.style.display = 'none'
        }
    }, [warmWelcomeEnabled, loading])

    useEffect(() => {
        let initialized = false
        const _: Window | Record<string, never> = window || {}
        _.HIDE_POWERED_BY_GORGIAS = true
        _.GORGIAS_CHAT_APP_ID = '6766'
        _.GORGIAS_CHAT_BASE_URL = 'us-east1-898b.production.gorgias.chat'
        _.GORGIAS_API_BASE_URL = 'config.gorgias.chat'
        if (loading === null) {
            const e = new XMLHttpRequest()
            e.open('GET', 'https://config.gorgias.chat/applications/6766', !0)
            e.onload = function () {
                if (4 === e.readyState && 200 === e.status) {
                    const n = JSON.parse(e.responseText)
                    if (!n.application || !n.bundleVersion) throw new Error('Error loading Gorgias chat script')
                    if (
                        ((_.GORGIAS_CHAT_APP = n.application),
                        (_.GORGIAS_CHAT_BUNDLE_VERSION = n.bundleVersion),
                        n && n.texts && (_.GORGIAS_CHAT_TEXTS = n.texts),
                        n && n.sspTexts && (_.GORGIAS_CHAT_SELF_SERVICE_PORTAL_TEXTS = n.sspTexts),
                        !document.getElementById('gorgias-chat-container'))
                    ) {
                        const o = document.createElement('div')
                        ;(o.id = 'gorgias-chat-container'), document.body.appendChild(o)
                        const r = document.createElement('script')
                        // @ts-ignore - defer is boolean
                        r.setAttribute('defer', !0),
                            (r.src =
                                'https://client-builds.production.gorgias.chat/{bundleVersion}/static/js/main.js'.replace(
                                    '{bundleVersion}',
                                    n.bundleVersion,
                                )),
                            document.body.appendChild(r)
                        setLoading(false)
                    } else console.error('Failed request GET - https://config.gorgias.chat/applications/6766')
                }
            }

            e.onerror = function (_) {
                console.error(_)
            }

            // Initial script request
            setLoading(true)
            e.send()
        }

        const onMessageSent = function (data) {
            if (data?.content?.metadata?.capturedMail) {
                // We might not know this from auth, but we'll know it if they enter their email in the chat
                customer.identify(data.content.metadata.capturedMail)
            }

            // since this lives in an iframe, we manually need to track events
            customer.track('chat - customer sent - message', {
                isBusinessHours: window.GorgiasChat.isBusinessHours(),
            })
        }

        // Note this triggers any time a dependency changes
        // which should be ok as we're just update context values in Gorgias
        window?.GorgiasChat?.init().then(() => {
            initialized = true
            // Prevent Gorgias from downloading Segment
            window.GorgiasChat.disableSegment()
            // Identify customer + shopify context
            if (customer?.identity) {
                window.GorgiasChat.setShopifyContext(process.env.SHOPIFY_PERMANENT_DOMAIN, customer.identity)
                window.GorgiasChat.captureUserEmail(customer.identity)
            } else {
                window.GorgiasChat.setShopifyContext(process.env.SHOPIFY_PERMANENT_DOMAIN)
            }
            /**
             * Setup listeners to messages:sent.
             * If the message contains a user's email, identify them in Heap
             */
            window.GorgiasChat.on('message:sent', onMessageSent)
        })

        // deregister listeners
        return () => {
            if (initialized) window?.window.GorgiasChat?.off('message:sent', onMessageSent)
        }
    }, [customer, loading])

    return null
}
