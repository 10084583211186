import * as React from 'react'
import { IPrescriptionLineItem } from 'interfaces/prescription-line-item'
import { IUiResources } from 'interfaces/ui-resource'
import { useRXCartContext } from 'context/rx-cart-context'
import { getDrugNameWithStrength } from '@/lib/util/prescription'
import CartLineItem from './cart-line-item'
import { getFormattedNumberWithCurrency } from '@/lib/util/currency'
import { DISCOUNTS } from 'enum/discounts'

interface IProps {
    lineItem: IPrescriptionLineItem
    uiResources: IUiResources
}

export function CartRxLineItem({ uiResources, lineItem }: IProps): React.ReactElement {
    const { removeLineItem, invoice, invoiceGenerationState } = useRXCartContext()

    // find corresponding line item in the resource
    const correspondingLineItemInInvoice = invoice?.line_items?.find((lt) => {
        const drugId = lt.properties.find((property) => property.name === 'drug_id')?.value
        return drugId === lineItem.drug.id
    })

    const membershipDiscount =
        correspondingLineItemInInvoice?.applied_discount?.title === DISCOUNTS.MEMBERSHIP_DISCOUNT
            ? Number(correspondingLineItemInInvoice?.applied_discount.amount)
            : 0

    const priceBeforeDiscount = Number(correspondingLineItemInInvoice?.price)
    const priceAfterDiscount = priceBeforeDiscount - membershipDiscount

    return (
        <CartLineItem
            image={uiResources['rxCartLineItemImage'].media}
            title={getDrugNameWithStrength({ drug: lineItem.drug })}
            price={priceAfterDiscount}
            onRemove={() => removeLineItem(lineItem.id)}
            loading={invoiceGenerationState === 'generating'}
            body={<span>{lineItem.requestedQuantityWithLabel}</span>}
            isAvailable={true}
            compareAtPrice={membershipDiscount > 0 ? getFormattedNumberWithCurrency(priceBeforeDiscount) : null}
        />
    )
}
