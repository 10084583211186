import * as React from 'react'
import globalManifest from 'data/global-manifest.json'
import { INavigation } from 'interfaces/navigation'
import NavigationBlocks from '../navigation-blocks'
import styles from './style.module.css'
import Link from 'next/link'
import NavigationButtons from '../navigation-buttons'
import NavigationHorizontalList from '../navigation-horizontal-list'
import NavigationIcons from '../navigation-icons'
import NavigationVerticalList from '../navigation-vertical-list'

interface IProps {
    isNavbarTransparent?: boolean
    setIsDropdownDropped: React.Dispatch<React.SetStateAction<boolean>>
    isDropdownDropped: boolean
}

const {
    megaMenuNavigation: {
        desktopMegaMenu: {
            data: {
                navigationParentCollection: { items: desktopNavigationItems },
            },
        },
        additionalNavigation: {
            data: {
                navigationCollection: { items: additionalNavigationItems },
            },
        },
    },
} = globalManifest

const Navigation = ({
    navigation,
    handleLinkClick,
}: {
    navigation: INavigation
    handleLinkClick: () => void
}): React.ReactElement => {
    const links = navigation?.linksCollection?.items

    if (!links?.length) {
        return null
    }

    switch (navigation.renderType) {
        case 'blocks':
            return <NavigationBlocks handleLinkClick={handleLinkClick} links={links} />

        case 'button':
            return (
                <div className="mt-5">
                    <NavigationButtons handleLinkClick={handleLinkClick} links={links} />
                </div>
            )

        case 'vertical list':
            return <NavigationVerticalList handleLinkClick={handleLinkClick} links={links} />

        case 'horizontal list':
            return (
                <div className="border-t border-b border-gray-medium py-3.5">
                    <NavigationHorizontalList handleLinkClick={handleLinkClick} links={links} />
                </div>
            )

        case 'icons':
            return (
                <div className="flex justify-between items-center">
                    <NavigationIcons handleLinkClick={handleLinkClick} links={links} />
                </div>
            )

        default:
            return null
    }
}

const DesktopMegaMenu = ({
    isNavbarTransparent,
    setIsDropdownDropped,
    isDropdownDropped,
}: IProps): React.ReactElement => {
    const [allowHoverInteraction, setAllowHoverInteraction] = React.useState(true)

    // when we click link, disable hover interaction for a brief
    // moment so the dropdown closes
    const handleLinkClick = React.useCallback(() => {
        setAllowHoverInteraction(false)

        const timer = setTimeout(() => {
            setAllowHoverInteraction(true)
        }, 400)

        return () => {
            clearTimeout(timer)
        }
    }, [])

    if (!desktopNavigationItems.length) {
        return null
    }

    const commonListItemClassNames = 'h-full'

    return (
        <nav className="h-full hidden lg:flex">
            <ul className={`flex h-full ${allowHoverInteraction ? styles['list'] : ''} list-item-x`}>
                {desktopNavigationItems.map((item) => {
                    return (
                        <li
                            key={item.sys.id}
                            className={`first:-ml-5 hover:border-black px-7.5 ${commonListItemClassNames} ${styles['list-item']}`}
                            onMouseEnter={() => setIsDropdownDropped(true)}
                            onMouseLeave={() => setIsDropdownDropped(false)}
                        >
                            <div
                                className={`flex items-center font-bold h-full text-xbase ${
                                    isNavbarTransparent ? 'text-white' : 'text-black'
                                }`}
                            >
                                {item.name}{' '}
                            </div>

                            {/* dropdown  */}
                            {item.subNavigationsCollection.items.length > 0 && (
                                <div
                                    className={`${styles['navigation-dropdown-grid-wrapper']} absolute left-0 -z-10 w-full`}
                                >
                                    <div
                                        className={`bg-white ${isDropdownDropped ? 'border-t border-gray-medium' : ''}
                                     ease-out overflow-hidden px-15 ${styles['navigation-dropdown-grid']}`}
                                    >
                                        {item.subNavigationsCollection.items.map((nav) => {
                                            return (
                                                <div key={nav.sys.id}>
                                                    {nav.name && (
                                                        <div className="text-xsm text-gray font-bold tracking-wide">
                                                            {nav.name}
                                                        </div>
                                                    )}
                                                    {nav.linksCollection.items.length > 0 && (
                                                        <Navigation
                                                            navigation={nav as INavigation}
                                                            handleLinkClick={handleLinkClick}
                                                        />
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )}
                        </li>
                    )
                })}
            </ul>

            {/* semantically this and the above ul can be same, and
            probably is more accurate, split here to fix an issue dropdown
            animation */}
            {additionalNavigationItems[0]?.linksCollection?.items?.length > 0 && (
                <ul className="flex h-full">
                    {/* additional links - these are plain links */}
                    {additionalNavigationItems[0]?.linksCollection?.items?.map((item) => (
                        <li
                            key={item.sys.id}
                            className={`${
                                isNavbarTransparent ? 'hover:border-white' : 'hover:border-black'
                            } ${commonListItemClassNames}`}
                        >
                            <Link
                                href={item.href}
                                className={`font-bold h-full w-full flex items-center px-7.5 ${
                                    isNavbarTransparent ? 'text-white' : 'text-black'
                                }`}
                            >
                                {item.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </nav>
    )
}

export default DesktopMegaMenu
