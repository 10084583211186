export interface ICustomerContext {
    /**
     * userId is currently set by Heap
     */
    userId: string
    /**
     * identity is currently set by Heap
     */
    identity: string
    loading: boolean
    track: (eventName: string, eventProperties?: Record<string, string | boolean | number | string[]>) => void
    identify: (email: string) => void
    clearEventProperties: () => void
    addEventProperties: (eventProperties: Record<string, string | number | boolean>) => void
}

export enum CUSTOMER_KNOWN_EVENT_NAMES {
    ADD_TO_CART = 'AddToCart',
}
