import * as React from 'react'

const CrossIcon = ({ stroke = '#3C3C3C', className = '', height = '10', width = '10' } = {}): React.ReactElement => (
    <svg className={className} width={width} height={height} viewBox="0 0 10 10" fill="none">
        <path
            d="M2.00012 2L5.00012 5L2.00012 8"
            stroke={stroke}
            strokeWidth="1.71429"
            strokeLinecap="square"
            fill="none"
        />
        <path
            d="M7.99988 2L4.99988 5L7.99988 8"
            stroke={stroke}
            strokeWidth="1.71429"
            strokeLinecap="square"
            fill="none"
        />
    </svg>
)

export default CrossIcon
