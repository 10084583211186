import * as React from 'react'
import { CartMedicineLineItem } from 'components/cart/cart-medicine-line-item'
import Button from 'components/buttons/button'
import { IUiResources } from 'interfaces/ui-resource'
import { getFormattedNumberWithCurrency } from 'lib/util/currency'
import { useOTCCartContext } from 'context/otc-cart-context'
import { useAuth } from 'components/auth/auth'
import { CartKitLineItem } from './cart-kit-line-item'
import globalManifest from 'data/global-manifest.json'
import useOTCCartCalculation from 'hooks/useOTCCartCalculation'
import CartEnvironmentalImpact from './cart-environmental-impact'
import { CartPromotions } from './cart-promotions'
import { wordInsideCurlyBracketsRegexp } from 'lib/util/regexps'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import { CartGenericProductLineItem } from './cart-generic-product-line-item'
import ArrowRightIcon from 'icons/arrow-right'
import { colors } from 'theme.cjs'
import { RenderNode } from '@contentful/rich-text-react-renderer'
import styles from './style.module.css'

interface Props {
    uiResources: IUiResources
    inSidebar?: boolean
}

const { resources: globalUIResources, coupon: couponStaticData } = globalManifest ?? {}
const stickyCheckoutButtonContainerHeight = '62px'

const shippingThreshold = Number(globalUIResources?.['shippingThreshold']?.value as string)

const OTCCart = ({ uiResources, inSidebar = true }: Props): React.ReactElement => {
    const {
        cartLineItems,
        checkout,
        isCheckingOut,
        isFetchingDetailsOfCartItems,
        kitLineItems,
        isFetchingRemoteCheckout,
        genericProductLineItems,
        totalLineItems,
    } = useOTCCartContext()

    const hasItemsInCart = cartLineItems.length || kitLineItems.length || genericProductLineItems.length

    const { isAuthenticated, loginWithRedirect } = useAuth()
    const { discounts, subTotal, total, couponDiscount } = useOTCCartCalculation()

    const [isCartFoooterDrawerOpen, setIsCartFooterDrawerOpen] = React.useState(false)

    const login = () => loginWithRedirect({ appState: { returnTo: '/cart' } })

    /**
     * get jsx from string template from Contentful
     */
    const loginMessageTemplate = (uiResources?.['loginInCartTemplate']?.value as string) ?? ''

    // split string with the first occurence of {text} pattern
    const loginMessageTemplateSplit = loginMessageTemplate.split(wordInsideCurlyBracketsRegexp)

    // keep matched word to use in jsx
    const matchedWord = loginMessageTemplate.match(wordInsideCurlyBracketsRegexp)?.[0]

    // use button with matched word in between split strings
    const loginMessage = (
        <div className="text-gray mt-1">
            {loginMessageTemplateSplit[0]}{' '}
            <button className="underline" onClick={login}>
                {matchedWord?.replace('{', '')?.replace('}', '')}
            </button>
            {loginMessageTemplateSplit[1]}
        </div>
    )

    const couponDiscountLabelRenderNode: RenderNode = {
        // eslint-disable-next-line react/display-name
        [BLOCKS.PARAGRAPH]: (_node, children) => <div>{children}</div>,
    }

    return (
        <div className="h-full flex flex-col justify-between max-md-h:justify-start -mt-1">
            {/* setting height 0 and grow 1 makes the element expand only to available height */}
            <div
                className={`${
                    inSidebar
                        ? 'h-0 grow max-md-h:h-[calc(100%-var(--stickyCheckoutButtonContainerHeight)-25px)] max-md-h:grow-0 flex flex-col max-md-h:border-b max-md-h:border-b-gray-lighter'
                        : ''
                }`}
                style={{
                    ['--stickyCheckoutButtonContainerHeight' as string]: stickyCheckoutButtonContainerHeight,
                }}
            >
                {/* line items */}
                {totalLineItems !== 0 && (
                    <ul className={styles['cart-list']}>
                        {cartLineItems.map((cartLineItem) => (
                            <li className={styles['cart-list-item']} key={cartLineItem.variantId}>
                                <CartMedicineLineItem uiResources={uiResources} cartLineItem={cartLineItem} />
                            </li>
                        ))}
                        {genericProductLineItems.map((genericProductLineItem) => (
                            <li className={styles['cart-list-item']} key={genericProductLineItem.variantId}>
                                <CartGenericProductLineItem
                                    uiResources={uiResources}
                                    genericProductLineItem={genericProductLineItem}
                                />
                            </li>
                        ))}
                        {kitLineItems.map((kitLineItem) => (
                            <li className={styles['cart-list-item']} key={kitLineItem.id}>
                                <CartKitLineItem kitLineItem={kitLineItem} uiResources={uiResources} />
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            {/* background shadow */}
            {isCartFoooterDrawerOpen && (
                <div className="hidden max-md-h:block bg-black/25 absolute top-0 left-0 h-full w-full" />
            )}

            <div
                className={`bg-white max-md-h:w-full max-md-h:absolute max-md-h:bottom-0 max-md-h:shadow-md-lift transition-transform ${
                    isCartFoooterDrawerOpen
                        ? ''
                        : 'max-md-h:translate-y-[calc(100%-var(--stickyButtonContainerHeight))]'
                }`}
                style={{
                    ['--stickyButtonContainerHeight' as string]: stickyCheckoutButtonContainerHeight,
                }}
            >
                <button
                    className="hidden max-md-h:block px-5 py-1 shadow-md-lift bg-white absolute rounded-5 left-1/2 -translate-x-1/2 translate-y-[calc(-100%-4px)]"
                    onClick={() => setIsCartFooterDrawerOpen(!isCartFoooterDrawerOpen)}
                >
                    <ArrowRightIcon
                        className={isCartFoooterDrawerOpen ? 'rotate-90' : '-rotate-90'}
                        stroke={colors.mineshaft}
                        strokeWidth={2}
                        height="14"
                        width="7"
                    />
                </button>

                {!isCartFoooterDrawerOpen && (
                    <div
                        className="hidden max-md-h:block p-2.5 h-[var(--height)] shadow-md-lift"
                        style={{
                            ['--height' as string]: stickyCheckoutButtonContainerHeight,
                        }}
                    >
                        <Button
                            loading={isCheckingOut}
                            disabled={!hasItemsInCart || isFetchingDetailsOfCartItems || isCheckingOut}
                            onClick={checkout}
                            className="hidden max-md-h:block bg-[black] w-full"
                            data-cy="checkout-button-short-screens"
                        >
                            {uiResources?.checkoutButton?.value}{' '}
                            {!isFetchingDetailsOfCartItems && !isFetchingRemoteCheckout
                                ? ' - ' + getFormattedNumberWithCurrency(total, undefined)
                                : null}
                        </Button>
                    </div>
                )}

                <div className="max-md-h:max-h-[calc(100vh-100px)] overflow-y-auto">
                    {/* cart impact */}
                    <div className="px-5 py-4 border-t border-gray-lighter">
                        <CartEnvironmentalImpact />
                    </div>

                    {/* cart calculations */}
                    <div className="px-5 py-4 w-full border-t border-gray-lighter flex flex-col">
                        {/* subtotal */}
                        <div className="text-sm md:text-xbase flex justify-between">
                            <span>{uiResources?.shoppingCartSubtotal?.value}</span>
                            {isFetchingDetailsOfCartItems || isFetchingRemoteCheckout ? (
                                <div className="h-5 w-24 inline-block bg-gray rounded placeholder-skeleton" />
                            ) : (
                                <span data-cy="cart-subtotal" suppressHydrationWarning>
                                    {getFormattedNumberWithCurrency(subTotal, undefined)}
                                </span>
                            )}
                        </div>

                        {/* coupon discount */}
                        {couponDiscount > 0 && (
                            <>
                                <span
                                    className="mt-1 text-sm md:text-xbase flex justify-between"
                                    suppressHydrationWarning
                                    data-cy="cart-coupon-discount"
                                >
                                    <ContentfulRichText
                                        // @ts-ignore
                                        content={couponStaticData?.richSubHeader?.json}
                                        className="coupon-discount-label"
                                        renderNode={couponDiscountLabelRenderNode}
                                    />
                                    {isFetchingRemoteCheckout ? (
                                        <div className="h-5 w-24 inline-block bg-gray rounded placeholder-skeleton" />
                                    ) : (
                                        <span className="font-bold text-allergyRelief-text" suppressHydrationWarning>
                                            -{getFormattedNumberWithCurrency(couponDiscount, undefined)}
                                        </span>
                                    )}
                                </span>
                            </>
                        )}

                        {/* discounts in cart */}
                        {Object.keys(discounts).map((discountKey) => {
                            const { amount, currencyCode, percentage } = discounts[discountKey]

                            if (amount === 0) return undefined

                            return (
                                <div className="mt-1.5 text-sm md:text-xbase flex justify-between" key={discountKey}>
                                    <span className="lowercase first-letter:uppercase">
                                        {discountKey} {percentage && `(${percentage}%)`}
                                    </span>
                                    {isFetchingRemoteCheckout ? (
                                        <div className="h-5 w-24 inline-block bg-gray rounded placeholder-skeleton" />
                                    ) : (
                                        <span className="font-bold text-allergyRelief-text" suppressHydrationWarning>
                                            -{getFormattedNumberWithCurrency(amount, currencyCode)}
                                        </span>
                                    )}
                                </div>
                            )
                        })}

                        {/* shipping label */}
                        {total >= shippingThreshold && (
                            <div data-cy="free-shipping" className="mt-1.5 text-sm md:text-xbase flex justify-between">
                                <span>{uiResources?.['shippingLabel']?.value}</span>
                                <span className="font-bold text-allergyRelief-text">
                                    {uiResources?.['freeLabel']?.value}
                                </span>
                            </div>
                        )}

                        {/* total */}
                        <div className="mt-1.5 text-xbase font-bold flex justify-between">
                            <span>{uiResources?.orderTotalLabel?.value}</span>
                            {isFetchingDetailsOfCartItems || isFetchingRemoteCheckout ? (
                                <div className="h-5 w-24 inline-block bg-gray rounded placeholder-skeleton" />
                            ) : (
                                <div>
                                    <span data-cy="cart-total" suppressHydrationWarning>
                                        {getFormattedNumberWithCurrency(total, undefined)}
                                    </span>
                                    <span className="font-bold">*</span>
                                </div>
                            )}
                        </div>

                        <div className="text-xs text-gray-darker mt-2.5 text-right">
                            {globalUIResources['cartTotalDisclaimer'].value}*
                        </div>

                        <Button
                            data-cy="checkout-button"
                            loading={isCheckingOut}
                            disabled={!hasItemsInCart || isFetchingDetailsOfCartItems || isCheckingOut}
                            onClick={checkout}
                            className="bg-[black] mt-2.5"
                        >
                            {uiResources?.checkoutButton?.value}
                        </Button>

                        <div className="mt-2.5 text-xs text-center">
                            <span>{globalUIResources?.['cartHeaderMoneyBackGuaranteeLabel']?.value}</span>

                            {!isAuthenticated && loginMessage}
                        </div>
                    </div>

                    {/* coupon promotion */}
                    <CartPromotions />
                </div>
            </div>
        </div>
    )
}

export default OTCCart
