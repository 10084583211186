import * as React from 'react'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect'

const lgBreakPoint = Number(screens.lg.replace('px', ''))
export const mobileScreenQuery = `(max-width: ${lgBreakPoint - 1}px)`

const useMobileScreenDetection = (): boolean => {
    const [isMobileScreen, setIsMobileScreen] = React.useState(true)

    useIsomorphicLayoutEffect(() => {
        const mql = window.matchMedia(mobileScreenQuery)

        setIsMobileScreen(mql.matches)

        const mediaChangeListener = (e: MediaQueryListEvent) => {
            setIsMobileScreen(e.matches)
        }

        if (typeof mql.addEventListener === 'function') {
            mql.addEventListener('change', mediaChangeListener)
        } else {
            // backwards compatability
            mql.addListener(mediaChangeListener)
        }

        return () => {
            if (typeof mql.addEventListener === 'function') {
                mql.removeEventListener('change', mediaChangeListener)
            } else {
                // backwards compatability
                mql.removeListener(mediaChangeListener)
            }
        }
    }, [])

    return isMobileScreen
}

export default useMobileScreenDetection
