import * as React from 'react'
import Portal from 'components/portal'
import styles from './style.module.css'
import { IconButton } from 'components/buttons/button'
import CrossIcon from 'icons/CrossIcon'
import { colors } from 'theme.cjs'
import { DIRECTION_VERTICAL } from 'enum/direction'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import globalManifest from 'data/global-manifest.json'

interface IProps {
    children: React.ReactNode
    closeModal: () => void
    // use to override z-index value
    zIndexClassName?: string
    fullPage?: boolean
    showCrossIcon?: boolean
    className?: string
    position?: DIRECTION_VERTICAL
    radiusClassName?: string
    modalWrapperClassName?: string
    crossButtonClassName?: string
    // freezes scroll of page behind modal
    freezePage?: boolean
    crossIconStroke?: string
    childrenWrapperClassName?: string
}

const { resources: uiResources } = globalManifest

// todo: refactor component
const Modal = ({
    children,
    closeModal,
    zIndexClassName,
    fullPage = false,
    showCrossIcon = true,
    className,
    position = DIRECTION_VERTICAL.MIDDLE,
    radiusClassName,
    modalWrapperClassName,
    crossButtonClassName,
    freezePage = true,
    crossIconStroke = colors.white,
    childrenWrapperClassName = '',
}: IProps): React.ReactElement => {
    // should render is used for transitions
    // start with false so we can transition the modal in
    const [shouldRender, setShouldRender] = React.useState(false)

    const closeWithTransition = () => {
        setShouldRender(false)
    }

    useIsomorphicLayoutEffect(() => {
        if (!freezePage) {
            return
        }

        const bodyEl = document.body

        // only update scroll on body when opening a modal
        // and there's not other modal open
        if (!window.activeModals) {
            if (bodyEl) {
                // with overlaying scrollbar, window.innerWidth and body.cliendWidth is the same
                const scrollBarWidth = window.innerWidth - bodyEl.clientWidth

                document.documentElement.style.setProperty('--scrollBarGutter', scrollBarWidth + 'px')
                bodyEl.classList.add('no-scroll')
            }

            window.activeModals = 1
        } else {
            window.activeModals += 1
        }

        return () => {
            // add scroll back to body when closing the only open modal
            if (window.activeModals === 1) {
                if (bodyEl) {
                    document.documentElement.style.setProperty('--scrollBarGutter', '0px')
                    bodyEl.classList.remove('no-scroll')
                }
                window.activeModals = 0
            } else {
                window.activeModals -= 1
            }
        }
    }, [freezePage])

    React.useEffect(() => {
        // the transition logic should work without this timeout trick
        // we start with shouldRender as false and after the first
        // render we set it to true, which should be enough to
        // transition from one state to another, but it's not working
        const timer = setTimeout(() => {
            setShouldRender(true)
        }, 50)

        const keyListener = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                setShouldRender(false)
            }
        }

        document.addEventListener('keyup', keyListener)

        return () => {
            document.removeEventListener('keyup', keyListener)
            clearTimeout(timer)
        }
    }, [])

    const containerRef = React.useRef()

    const handleModalContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === containerRef.current) {
            closeWithTransition()
        }
    }

    let transitionClasses = ` transition-transform ease-out duration-300`

    if (shouldRender) {
        transitionClasses += ' translate-y-0'
    } else {
        transitionClasses += ' translate-y-full bg-gray-darker/0'
    }

    let modalPositionClass

    switch (position) {
        case DIRECTION_VERTICAL.MIDDLE:
            modalPositionClass = 'items-center'
            break
        case DIRECTION_VERTICAL.BOTTOM:
            modalPositionClass = 'items-end'
            break
        case DIRECTION_VERTICAL.TOP:
            modalPositionClass = 'items-start'
            break

        default:
            break
    }

    return (
        <Portal>
            <div
                onTransitionEnd={() => {
                    // put this check because transition end fires on
                    // both mounting and unmounting
                    if (!shouldRender) {
                        closeModal()
                    }
                }}
                ref={containerRef}
                onClick={handleModalContainerClick}
                className={`w-full h-full bg-gray-darker/70 fixed ${
                    zIndexClassName ?? 'z-[60]'
                } top-0 left-0 ${transitionClasses} ${styles['modal-wrapper']} ${
                    fullPage ? 'full-page' : `flex py-3 ${modalPositionClass} ${modalWrapperClassName}`
                }`}
                data-cy="modal"
                // id is being referenced in other files, be cautious when changing
                id="modal"
            >
                <div
                    className={`cabinet-container modal-container relative ${styles['modal']} ${
                        fullPage ? 'h-full' : 'w-full sm:w-10/12 md:w-8/12 lg:w-1/2 xl:w-2/5 2xl:w-1/3 mx-auto'
                    } ${className}`}
                >
                    {fullPage ? (
                        <div
                            className={`${
                                radiusClassName ?? 'rounded-t-xl overflow-hidden'
                            } h-full bg-white overflow-hidden modal-inner-container"`}
                        >
                            {children}
                        </div>
                    ) : (
                        <div
                            className={`${
                                radiusClassName ?? 'rounded-xl'
                            } ${childrenWrapperClassName} h-full bg-white overflow-hidden modal-inner-container`}
                        >
                            {children}
                        </div>
                    )}
                    {showCrossIcon && (
                        <IconButton
                            className={` bg-black absolute right-1 sm:right-0 top-0 translate-x-1/2 -translate-y-1/2 ${
                                zIndexClassName ?? 'z-50'
                            } ${crossButtonClassName}`}
                            label={uiResources?.closeModalLabel?.value?.toString() || 'close modal'}
                            onClick={closeModal}
                            data-cy="modal-close-button"
                        >
                            <CrossIcon stroke={crossIconStroke} />
                        </IconButton>
                    )}
                </div>
            </div>
        </Portal>
    )
}

export default Modal
