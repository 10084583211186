import * as React from 'react'
import globalManifest from 'data/global-manifest.json'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { sustainabilitySitePageSlug, sustainableSwapTourPageSlug } from 'misc-variables'
import styles from './style.module.css'

const { secondaryNavigation } = globalManifest

export const SecondaryNavbar = (): React.ReactElement => {
    const {
        pathname,
        query: { slug },
    } = useRouter()

    const isSustainabilityPage = slug === sustainabilitySitePageSlug
    const isSustainableSwapTourPage = slug === sustainableSwapTourPageSlug
    const isBlogsHomePage = pathname === '/blogs'
    const isBlogCategoryPage = pathname === '/blogs/[slug]'

    const navRef = React.useRef<HTMLElement>()

    React.useEffect(() => {
        document.documentElement.style.setProperty(
            '--secondaryNavbarHeight',
            (navRef.current?.clientHeight ?? 0) + 'px',
        )
    }, [])

    // these pages have transparent header, subnav does not sit well
    // with transparent headers/ absolutely positioned headers
    if (isSustainabilityPage || isBlogsHomePage || isBlogCategoryPage || isSustainableSwapTourPage) {
        return null
    }

    const stick = pathname === '/' // sticky in home page

    const links = secondaryNavigation['Secondary Navigation Bar'].linksCollection.items
    return (
        <nav
            ref={navRef}
            className={`bg-white flex border-b border-gray-medium ${
                stick ? 'sticky top-[var(--pageHeaderHeight)] z-40' : 'relative z-10'
            }`}
        >
            <ul className={`flex mx-auto px-5 sm:px-6 lg:px-15 overflow-auto scrollbar-hidden ${styles['nav-list']}`}>
                {links.map((link, index) => {
                    const [backgroundColor] = link.associatedColors?.split(',') ?? []

                    return (
                        <React.Fragment key={index}>
                            <li className="flex items-center flex-shrink-0">
                                <Link
                                    href={link.href}
                                    className="text-xs lg:text-xbase font-medium tracking-wide lg:tracking-normal flex items-center py-2.5 px-3 lg:px-6"
                                >
                                    <span>{link.name}</span>
                                    {link.associatedColors && (
                                        <div
                                            className="h-2 w-2 rounded-full ml-1.5 lg:ml-2"
                                            style={{ backgroundColor: backgroundColor }}
                                        />
                                    )}
                                </Link>
                            </li>
                            {(index === 1 || index === 7) && (
                                <div className="w-px h-full bg-gray-medium mx-3 lg:mx-6 flex-shrink-0" />
                            )}
                        </React.Fragment>
                    )
                })}
            </ul>
        </nav>
    )
}
