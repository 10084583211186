import { useFeature } from '@growthbook/growthbook-react'
import Script from 'next/script'
import { useEffect } from 'react'

/**
 * Loads the required scripts and configuration for the Warm Welcome chat bubble widget
 * 1. This is controlled via feature flag in our Growthbook account
 * 2. WarmWelcome requires URL configuration for this to run. They don't support non-standard ports
 *      so this will not run on localhost:3000. Quickest solution is to test over a local server or ngrok
 */
export default function WarmWelcome(): React.ReactElement | null {
    const { value: warmWelcomeEnabled } = useFeature('warm-welcome')

    useEffect(() => {
        if (warmWelcomeEnabled) {
            window.WIDGET_CONFIG = {
                globalWidgetId: '3e6aef75-3329-4b75-9189-50be6cf5fc62',
                baseUrl: 'https://app.warmwelcome.com',
            }
        }
    }, [warmWelcomeEnabled])

    if (warmWelcomeEnabled) {
        return <Script src="https://d7a97ajcmht8v.cloudfront.net/production/app.js" />
    }
    return null
}
