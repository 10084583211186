import * as React from 'react'
import Button from 'components/buttons/button'
import styles from './style.module.css'
import Modal from 'components/modal/modal'
import TickMarkWideIcon from 'icons/tick-mark-wide'
import { colors } from 'theme.cjs'
import CrossIcon from 'icons/CrossIcon'
import globalManifest from 'data/global-manifest.json'
import ContentfulRichText from 'components/rich-text'
import { useCustomerContext } from 'context/customer-context'
import { userSubscribeEventName } from 'misc-variables'
import TitleWithColonIcon from 'components/title-with-colon-icon/title-with-colon-icon'
import { fetchWithTimeout } from '@/lib/util/fetch'

const { commonCopy, resources: uiResources } = globalManifest
const subscribeLocationKey = 'footer'

const NewsletterSubscriptionForm = (): React.ReactElement => {
    const [emailOrPhone, setEmailOrPhone] = React.useState('')

    const [subscriptionSuccess, setSubscriptionSuccess] = React.useState(null)
    const [subscriptionError, setSubscriptionError] = React.useState(null)

    const [isSubscribing, setIsSubscribing] = React.useState(false)

    const { track } = useCustomerContext()

    const subscribeNewsletter = async () => {
        try {
            setIsSubscribing(true)

            const response = await fetchWithTimeout('/api/subscribe', {
                method: 'POST',
                body: JSON.stringify({ emailOrPhone, subscribeLocation: subscribeLocationKey }),
            })

            if (response.ok) {
                setSubscriptionSuccess(true)

                track(`Any Page - ${userSubscribeEventName} - Footer Input`, {
                    subscribeLocation: subscribeLocationKey,
                })
            } else {
                setSubscriptionError(true)
            }
        } catch (e) {
            setSubscriptionError(e)
        } finally {
            setIsSubscribing(false)
        }
    }

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        subscribeNewsletter()
    }

    return (
        <>
            {/* newsletter subscription */}
            <div className={`${styles['subscription-section']}`}>
                <div className="lg:hidden text-xs lg:text-base tracking-wide leading-140% mt-2.5 lg:mt-0">
                    {/* ts error: rich-text document checks against BLOCKS.DOCUMENT type, but it will always
                    be string in global-manifest.json */}
                    {/* @ts-ignore */}
                    <ContentfulRichText content={commonCopy['newsletterOffer'].value.json} />
                </div>

                <div className="hidden lg:block">
                    {/* @ts-ignore */}
                    <TitleWithColonIcon content={commonCopy['newsLetterSubscriptionDesktopTitle'].value.json} />

                    <hr className="border-t border-black/30 mt-2.5" />
                </div>

                <form onSubmit={handleFormSubmit} className="mt-7.5 w-full flex justify-center lg:justify-start">
                    <input
                        data-cy="email-input"
                        className={`ring-inset pl-6 py-2.5 pr-3 w-1/2 sm:w-auto grow sm:grow-0 rounded-l-full border border-r-0 border-gray bg-transparent ${styles['footer-input']}`}
                        placeholder={uiResources['enterEmailOrPhoneLabel'].value}
                        value={emailOrPhone}
                        onChange={(e) => setEmailOrPhone(e.target.value)}
                        required
                    />
                    <Button
                        data-cy="newsletter-subscribe-button"
                        loading={isSubscribing}
                        className={`ring-inset pr-6 pl-3.5 py-2.5 rounded-tl-none rounded-bl-none ${styles['join-button']}`}
                    >
                        {uiResources['joinTheClubLabel'].value}
                    </Button>
                </form>
            </div>
            {(subscriptionSuccess || subscriptionError) && (
                <Modal
                    closeModal={() => {
                        setSubscriptionSuccess(null)
                        setSubscriptionError(null)
                    }}
                >
                    <div className={`p-4 flex items-center ${styles['modal-content']}`}>
                        <div className="flex">
                            <div
                                className={`h-9 w-9 rounded-full mr-2.5 flex items-center justify-center shrink-0 ${
                                    subscriptionSuccess ? 'bg-success' : 'bg-error'
                                }`}
                            >
                                {subscriptionSuccess ? (
                                    <TickMarkWideIcon stroke={colors.white} />
                                ) : (
                                    <CrossIcon stroke={colors.white} />
                                )}
                            </div>
                            <span
                                data-cy={
                                    subscriptionSuccess ? 'subscription-success-message' : 'subscription-error-message'
                                }
                            >
                                {subscriptionSuccess ? (
                                    <ContentfulRichText
                                        // @ts-ignore
                                        content={commonCopy['newsletterSubscriptionSuccessMessage'].value.json}
                                    />
                                ) : (
                                    <ContentfulRichText
                                        // @ts-ignore
                                        content={commonCopy['newsLetterSubscriptionErrorMessage'].value.json}
                                    />
                                )}
                            </span>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )
}

export default NewsletterSubscriptionForm
