/**
 * Cancels request if it's still in pending
 * state until timeout
 * @param url string
 * @param options RequestInit
 * @param timeout number
 * @returns Promise<Response>
 */
export async function fetchWithTimeout(url: string, options: RequestInit = {}, timeout = 8000): Promise<Response> {
    let controller: AbortController
    let timeoutId: NodeJS.Timeout

    try {
        controller = new AbortController()
        timeoutId = setTimeout(() => controller.abort('Timeout'), timeout)
    } catch (e) {
        console.error(e)
    }

    const response = await fetch(url, {
        ...options,
        signal: controller?.signal,
    })

    clearTimeout(timeoutId)

    return response
}
