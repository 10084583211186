import * as React from 'react'
import { IPrescriptionLineItem } from 'interfaces/prescription-line-item'
import { fetchWithTimeout } from 'lib/util/fetch'
import * as Sentry from '@sentry/nextjs'
import { useAuth } from 'components/auth/auth'

type TRxCartInvoiceGenerationState = 'generating' | 'success' | 'error'

export interface IRXCartContext {
    lineItems: IPrescriptionLineItem[]
    addLineItem: (lineItem: IPrescriptionLineItem) => void
    removeLineItem: (lineItemId: string) => void
    totalLineItem: number
    invoice: Record<string, any>
    invoiceGenerationState: TRxCartInvoiceGenerationState
    generateInvoice: () => void
    replaceLineItem: (lineItem: IPrescriptionLineItem) => void
    lineItemToHighlightId: string
    setLineItemToHighlightId: React.Dispatch<React.SetStateAction<string>>
}

interface IProps {
    children: React.ReactNode
}

const RXCartContext = React.createContext<IRXCartContext>(null)

const RXCartContextProvider = ({ children }: IProps): React.ReactElement => {
    const { idToken, user } = useAuth()
    const [lineItems, setLineItems] = React.useState<IPrescriptionLineItem[]>([])

    const [lineItemToHighlightId, setLineItemToHighlightId] = React.useState<string>()

    const [invoice, setInvoice] = React.useState()
    const [invoiceGenerationState, setInvoiceGenerationState] = React.useState<TRxCartInvoiceGenerationState>()

    const addLineItem = React.useCallback((lineItem: IPrescriptionLineItem) => {
        setLineItems((lineItems) => [...lineItems, lineItem])
    }, [])

    const removeLineItem = React.useCallback((lineItemId: string) => {
        setLineItems((lineItems) => lineItems.filter((lineItem) => lineItem.id !== lineItemId))
    }, [])

    const replaceLineItem = React.useCallback((lineItem: IPrescriptionLineItem) => {
        setLineItems((lineItems) => {
            const existingLineItemIndex = lineItems.findIndex((lt) => lt.id === lineItem.id)

            if (existingLineItemIndex === -1) {
                return lineItems
            }

            const clonedLineItems = [...lineItems]
            clonedLineItems[existingLineItemIndex] = lineItem

            return clonedLineItems
        })
    }, [])

    const generateInvoice = React.useCallback(async () => {
        if (lineItems.length === 0 || !idToken) {
            return
        }

        setInvoiceGenerationState('generating')

        try {
            const response = await fetchWithTimeout(`${process.env.BACKEND_ENDPOINT}/api/rx/order/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`,
                },
                body: JSON.stringify({
                    customer_email: user.email,
                    requested_prescriptions: lineItems.map((lineItem) => {
                        return {
                            prescription_id: lineItem.id,
                            quantity: lineItem.requestedQuantity,
                            acknowledge_allow_stack_fills: lineItem.isStackingFillsConsented,
                        }
                    }),
                    // for now we assume this is true and only
                    // allow user to click on the checkout button if consented
                    // else we'd have to generate an invoice everytime these consents change
                    acknowledge_not_crc_agreement: true,
                    authorization_for_use_and_disclosure_agreement: true,
                    acknowledge_allow_stack_fills: lineItems.some((lineItem) => lineItem.isStackingFillsConsented),
                }),
            })

            if (!response.ok) {
                setInvoiceGenerationState('error')
            } else {
                const responseJSON = await response.json()
                const invoice = responseJSON.shopify_invoice

                setInvoice(invoice)
                setInvoiceGenerationState('success')
            }
        } catch (e) {
            Sentry.captureException(e)
            setInvoiceGenerationState('error')
        }
    }, [lineItems, idToken, user?.email])

    // update order everytime line items change
    React.useEffect(() => {
        const timer = setTimeout(async () => {
            generateInvoice()
        }, 300)

        return () => {
            clearTimeout(timer)
        }
    }, [generateInvoice])

    // clear invoice when cart is empty
    React.useEffect(() => {
        if (lineItems.length === 0) {
            setInvoice(null)
            setInvoiceGenerationState(null)
        }
    }, [lineItems])

    React.useEffect(() => {
        if (!lineItemToHighlightId) {
            return
        }

        const timer = setTimeout(() => {
            setLineItemToHighlightId(null)
        }, 2500)

        return () => {
            clearTimeout(timer)
        }
    }, [lineItemToHighlightId])

    const value: IRXCartContext = React.useMemo(
        () => ({
            lineItems,
            addLineItem,
            removeLineItem,
            totalLineItem: lineItems.length,
            invoice,
            invoiceGenerationState,
            generateInvoice,
            replaceLineItem,
            lineItemToHighlightId,
            setLineItemToHighlightId,
        }),
        [
            lineItems,
            addLineItem,
            removeLineItem,
            invoice,
            invoiceGenerationState,
            generateInvoice,
            replaceLineItem,
            lineItemToHighlightId,
        ],
    )
    return <RXCartContext.Provider value={value}>{children}</RXCartContext.Provider>
}

export const useRXCartContext = (): IRXCartContext => {
    const rxCartContext = React.useContext(RXCartContext)

    if (rxCartContext === undefined) {
        throw new Error('You cannot use useRXCartContext outside of RXCartContextProvider')
    }

    return rxCartContext
}

export default RXCartContextProvider
