import * as React from 'react'
import Sidebar from 'components/sidebar'
import { useSecondaryNavSidebarContext } from 'context/secondary-nav-sidebar-context'
import { DIRECTION_HORIZONTAL } from 'enum/direction'
import { SIZE } from 'enum/size'
import { MobileMegaMenu } from './mobile-mega-menu'
import ArrowRightIcon from 'icons/arrow-right'
import { colors } from 'theme.cjs'
import globalManifest from 'data/global-manifest.json'
import styles from './style.module.css'
import { SidebarHeaderCloseButton } from 'components/sidebar/sidebar-header'

const { resources: globalUIResources } = globalManifest

const SecondaryNavSidebar = (): React.ReactElement => {
    const { open, closeSidebar } = useSecondaryNavSidebarContext()

    return (
        <Sidebar
            open={open}
            onClose={closeSidebar}
            side={DIRECTION_HORIZONTAL.LEFT}
            size={SIZE.SMALL}
            title={globalUIResources['mobileMegaMenuSidebarTitle'].value}
            closeButton={
                <SidebarHeaderCloseButton
                    onClose={closeSidebar}
                    icon={
                        <ArrowRightIcon
                            height="8"
                            width="8"
                            className="rotate-180"
                            strokeWidth={2}
                            stroke={colors.mineshaft}
                        />
                    }
                />
            }
            asideClassName={styles['mobile-mega-menu--sidebar']}
            showTitleDivider
        >
            <MobileMegaMenu />
        </Sidebar>
    )
}

export default SecondaryNavSidebar
