import * as React from 'react'
import { Overlay } from 'components/overlay'
import { useProfileContext } from 'context/profile-context'
import { useSecondaryNavSidebarContext } from 'context/secondary-nav-sidebar-context'
import { useRouter } from 'next/router'
import { accountPagePath } from 'constants/url'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'
import CaretIcon from 'icons/caret-icon'

export function ProfileSelector() {
    const { profileLoadingStatus } = useProfileContext()
    const isMobile = useMobileScreenDetection()

    if (profileLoadingStatus !== 'idle') {
        return null
    }

    if (isMobile) {
        return <ProfileSelectorMobile />
    }

    return <ProfileSelectorDesktop />
}

function ProfileSelectorDesktop() {
    const { profiles, activeProfile, setActiveProfile, defaultProfile } = useProfileContext()

    const [isShowDropdown, setIsShowDropdown] = React.useState(false)

    if (profiles.length <= 1) {
        return (
            <div
                className="px-3 py-2.5 font-medium leading-4 -tracking-[0.45px] border rounded-full w-32 text-center truncate"
                data-cy="profile-selector"
            >
                {activeProfile.first_name}
            </div>
        )
    }

    return (
        <div className="relative" data-cy="profile-selector">
            <button
                className="px-3 py-2.5 font-medium leading-4 -tracking-[0.45px] border rounded-full w-32 flex space-x-1 items-center"
                onClick={() => setIsShowDropdown(!isShowDropdown)}
            >
                <span className="flex-1 truncate">{activeProfile.first_name}</span>{' '}
                <span className="text-[8px] text-right items-center">
                    <CaretIcon className={isShowDropdown ? 'rotate-180' : ''} />
                </span>
            </button>

            {isShowDropdown && (
                <Overlay className="w-32 !top-11">
                    {activeProfile.id !== defaultProfile.id && (
                        <button
                            className="w-full px-5 py-4 text-left text-sm font-bold leading-140% tracking-[0.14px] border-b border-gray-medium last:border-0 hover:bg-gray-lighter truncate"
                            onClick={() => {
                                setActiveProfile(defaultProfile)
                                setIsShowDropdown(false)
                            }}
                        >
                            {defaultProfile.first_name}
                        </button>
                    )}
                    {profiles.map((p) => {
                        if (p.id === activeProfile.id) {
                            return null
                        }
                        return (
                            <button
                                key={p.id}
                                className="w-full px-5 py-4 text-left text-sm font-bold leading-140% tracking-[0.14px] border-b border-gray-medium last:border-0 hover:bg-gray-lighter"
                                onClick={() => {
                                    setActiveProfile(p)
                                    setIsShowDropdown(false)
                                }}
                            >
                                {p.first_name}
                            </button>
                        )
                    })}
                </Overlay>
            )}
        </div>
    )
}

function ProfileSelectorMobile() {
    const { profiles, activeProfile, setActiveProfile, defaultProfile } = useProfileContext()
    const { closeSidebar } = useSecondaryNavSidebarContext()
    const { pathname } = useRouter()

    if (profiles.length <= 1 || pathname !== accountPagePath) {
        return null
    }

    const buttonClassName = `px-5 py-2.5 rounded-full text-xbase font-bold`

    return (
        <ul className="flex gap-3 flex-wrap">
            <li>
                <button
                    className={`${buttonClassName} 
                        ${
                            activeProfile.id === defaultProfile.id
                                ? 'border-2 border-black'
                                : 'border border-gray-medium hover:outline hover:outline-2'
                        }
                    `}
                    onClick={() => {
                        setActiveProfile(defaultProfile)
                        closeSidebar()
                    }}
                >
                    {defaultProfile.first_name}
                </button>
            </li>

            {profiles.map((p) => {
                return (
                    <li key={p.id}>
                        <button
                            className={`${buttonClassName} 
                              ${
                                  activeProfile.id === p.id
                                      ? 'border-2 border-black'
                                      : 'border border-gray-medium hover:outline hover:outline-2'
                              }
                            `}
                            onClick={() => {
                                setActiveProfile(p)
                                closeSidebar()
                            }}
                        >
                            {p.first_name}
                        </button>
                    </li>
                )
            })}
        </ul>
    )
}
