import { PRESCRIPTION_ORDER_STATUS } from 'enum/prescription/prescription-order-status'
import { PRESCRIPTION_RENEWAL_STATUS } from 'enum/prescription/prescription-renewal-status'
import { PRESCRIPTION_DERIVED_STATUS } from 'enum/prescription/prescription-derived-status'
import { PRESCRIPTION_TRANSFER_STATUS } from 'enum/prescription/prescription-transfer-status'
import { IPrescription } from 'interfaces/prescriptions/prescription'
import { colors } from 'theme'
import { IPrescriptionDrug } from 'interfaces/prescriptions/prescription-drug'

/**
 * Returs color associated with a prescription status
 * @param status {string}
 */
export function getPrescriptionStatusColor(status: PRESCRIPTION_DERIVED_STATUS) {
    const white = [
        PRESCRIPTION_DERIVED_STATUS.ACTIVE,
        PRESCRIPTION_DERIVED_STATUS.TRANSFERRING,
        PRESCRIPTION_DERIVED_STATUS.PREPPING,
        PRESCRIPTION_DERIVED_STATUS.SHIPPED,
        PRESCRIPTION_DERIVED_STATUS.RENEWAL_IN_PROGRESS,
    ]
    const gray = [PRESCRIPTION_DERIVED_STATUS.INACTIVE]
    const yellow = [PRESCRIPTION_DERIVED_STATUS.ORDER_SOON, PRESCRIPTION_DERIVED_STATUS.AVAILABLE_TO_ORDER]
    const red = [
        PRESCRIPTION_DERIVED_STATUS.EXPIRED,
        PRESCRIPTION_DERIVED_STATUS.ERROR,
        PRESCRIPTION_DERIVED_STATUS.RENEWAL_NEEDED,
    ]

    if (red.includes(status)) {
        return colors.urgentRed
    }

    if (yellow.includes(status)) {
        return colors.yellow
    }

    if (white.includes(status)) {
        return colors.white
    }

    if (gray.includes(status)) {
        return colors.mediumGray
    }
}

/**
 * Returns status of active prescription
 * Here active means unarchived
 * @param prescription {IPrescription}
 * @returns PRESCRIPTION_DERIVED_STATUS
 */
export function getActivePrescriptionStatus(prescription: IPrescription): PRESCRIPTION_DERIVED_STATUS {
    const {
        is_expired,
        is_available_for_first_order,
        is_order_soon,
        transfer_status,
        renewal_status,
        order_status,
        active_order,
        is_can_renew,
    } = prescription

    const hasActiveOrder = Boolean(active_order)

    // precedence of derived status: error, expired, transferring, renewal needed, renewal in progress, prepping, shipped, available to order, order soon, active
    if (
        transfer_status === PRESCRIPTION_TRANSFER_STATUS.FAILED ||
        renewal_status === PRESCRIPTION_RENEWAL_STATUS.FAILED ||
        order_status === PRESCRIPTION_ORDER_STATUS.FAILED
    ) {
        return PRESCRIPTION_DERIVED_STATUS.ERROR
    }

    if (is_expired) {
        return PRESCRIPTION_DERIVED_STATUS.EXPIRED
    }

    if (
        transfer_status === PRESCRIPTION_TRANSFER_STATUS.CREATED ||
        transfer_status === PRESCRIPTION_TRANSFER_STATUS.SUBMITTED
    ) {
        return PRESCRIPTION_DERIVED_STATUS.TRANSFERRING
    }

    if (is_can_renew && !hasActiveOrder) {
        return PRESCRIPTION_DERIVED_STATUS.RENEWAL_NEEDED
    }

    if (
        (renewal_status === PRESCRIPTION_RENEWAL_STATUS.CREATED ||
            renewal_status === PRESCRIPTION_RENEWAL_STATUS.SUBMITTED) &&
        !hasActiveOrder
    ) {
        return PRESCRIPTION_DERIVED_STATUS.RENEWAL_IN_PROGRESS
    }

    if (
        transfer_status === PRESCRIPTION_TRANSFER_STATUS.COMPLETED &&
        (order_status === PRESCRIPTION_ORDER_STATUS.SUBMITTED || order_status === PRESCRIPTION_ORDER_STATUS.CREATED)
    ) {
        return PRESCRIPTION_DERIVED_STATUS.PREPPING
    }

    if (order_status === PRESCRIPTION_ORDER_STATUS.SHIPPED) {
        return PRESCRIPTION_DERIVED_STATUS.SHIPPED
    }

    if (is_available_for_first_order) {
        return PRESCRIPTION_DERIVED_STATUS.AVAILABLE_TO_ORDER
    }

    if (is_order_soon) {
        return PRESCRIPTION_DERIVED_STATUS.ORDER_SOON
    }

    return PRESCRIPTION_DERIVED_STATUS.ACTIVE
}

/**
 * Returns status of archived prescription
 * @param prescription IPrescription
 * @returns PRESCRIPTION_DERIVED_STATUS
 */
export function getArchivedPrescriptionStatus(prescription: IPrescription): PRESCRIPTION_DERIVED_STATUS {
    const { is_expired } = prescription

    if (is_expired) {
        return PRESCRIPTION_DERIVED_STATUS.EXPIRED
    }

    return PRESCRIPTION_DERIVED_STATUS.INACTIVE
}

/**
 * Returns the common copy key in Contentful for a prescription with one
 * of the error states
 *
 * @param prescription IPrescription
 *
 * @returns string
 */
export function getPrescriptionErrorMessageCommonCopyKey(prescription: IPrescription): string {
    if (prescription.transfer_status === PRESCRIPTION_TRANSFER_STATUS.FAILED) {
        return 'rxCardTransferErrorParagraph'
    }

    if (prescription.order_status === PRESCRIPTION_ORDER_STATUS.FAILED) {
        return 'rxCardOrderErrorParagraph'
    }

    if (prescription.renewal_status === PRESCRIPTION_RENEWAL_STATUS.FAILED) {
        return 'rxCardRenewalErrorParagraph'
    }
}

/**
 * Returns 'tablet' or 'capsule' if the drug form contains
 * tablet or capsule respectively. There are drug forms that are
 * tablet or capsule plus 'delaye release', 'extended release' and
 * perhaps more.
 *
 * @param string drugForm
 * @returns string
 */
export function getSimpleDrugFormName(drugForm: string) {
    if (typeof drugForm !== 'string') {
        return drugForm
    }

    if (drugForm.search(/tablet/i) !== -1) {
        return 'tablet'
    }

    if (drugForm.search(/capsule/i) !== -1) {
        return 'capsule'
    }

    return drugForm
}

/**
 * Returns drug name with drug strength if not already present
 */
export function getDrugNameWithStrength({ drug }: { drug: Pick<IPrescriptionDrug, 'details' | 'name'> }): string {
    const { drug_strength } = drug.details

    let title = drug.name

    if (!title.includes(drug_strength)) {
        title += ` ${drug_strength}`
    }

    return title
}
