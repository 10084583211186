import * as React from 'react'
import { ILink } from 'interfaces/link'
import Link from 'next/link'

interface IProps {
    links: ILink[]
    handleLinkClick?: () => void
}

export const NavigationButtons = ({ links, handleLinkClick }: IProps): React.ReactElement => {
    if (!links?.length) {
        return null
    }

    return (
        <>
            {links.map((link) => (
                <Link
                    href={link.href}
                    key={link.sys?.id}
                    onClick={() => handleLinkClick?.()}
                    className="button block lg:inline-block lg:button-primary lg:font-normal"
                >
                    {link.name}
                </Link>
            ))}
        </>
    )
}
