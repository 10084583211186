// Fetch data from Shopify
import { gql } from '@apollo/client/core/index'
import { IMedicine } from 'interfaces/medicine'
import { IShopifyProductVariant } from 'interfaces/product'
import { createApolloClient } from '../apollo-client'
import { deepClone } from '../util/misc'
import { priceElasticityExperimentControlVariationName } from '../../misc-variables'
import { IShopifyOrderLineItem } from 'interfaces/shopify-order'
import { LINE_ITEM_TYPE } from '../../enum/line-item-type'
const client = createApolloClient()

interface IMedicineCollection {
    items: IMedicine[]
}

export const SHOPIFY_PRODUCT_NODE_FRAGMENT = `
    fragment ShopifyProductNode on Product {
        id
        tags
        #20 variants is a safe enough value?
        variants(first: 20) {
            edges {
                node {
                    id
                    title
                    sku
                    compareAtPrice {
                        amount
                        currencyCode
                    }
                    availableForSale
                    price {
                        amount
                        currencyCode
                    }
                }
            }
        }
    }
`

export const SHOPIFY_PRODUCT_BY_ID_QUERY = `
    query ShopifyProductById ($id: ID!) {
        product (id: $id) {
            ...ShopifyProductNode
        }
    }
    ${SHOPIFY_PRODUCT_NODE_FRAGMENT}
`

export const SHOPIFY_PRODUCTS_BY_IDS_QUERY = `
    query ShopifyProductsByIds ($ids: [ID!]!) {
        nodes (ids: $ids) {
            ...ShopifyProductNode
        }
    }
    ${SHOPIFY_PRODUCT_NODE_FRAGMENT}
`

export const SHOPIFY_PRODUCTS_BY_SEARCH_QUERY = `
    ${SHOPIFY_PRODUCT_NODE_FRAGMENT}
    query ShopifyProdctsFromTags($searchQuery: String) {
        products(first: 100, query: $searchQuery) {
            edges {
                node {
                    ...ShopifyProductNode
                }
            }
        }
    }
`

const SHOPIFY_PRODUCT_VIA_VARIANT_QUERY = `
    ... on ProductVariant {
        product {
            id
            title
            variants (first: 10) {
                edges {
                    node {
                        id
                        title
                        sku
                        availableForSale
                        quantityAvailable
                        price {
                            amount
                            currencyCode
                        }
                        compareAtPrice {
                            amount
                            currencyCode
                        }
                    }
                }
            }
        }
    }
`

// queries shopify details of on product
const productParentQuery = gql`
    query ShopifyProduct ($id: ID!){
        node(id: $id) {
            ... on ProductVariant {
                ${SHOPIFY_PRODUCT_VIA_VARIANT_QUERY}
            }
        }
    }
`

export const fetchProductParentFromVariant = async (productVariantId: string) => {
    if (!productVariantId) {
        console.error('Cannot fetch without a product variant Id')
        return null
    }
    const data = await client.query({
        query: productParentQuery,
        variables: {
            id: productVariantId,
        },
    })

    if (data.errors) {
        throw data.errors
    }

    return data?.data?.node?.product
}

/**
 * Get the latest pricing and availbility for a given list of Shopify Product variants
 *
 * Example usage: on page load, update the cart localstorage state with latest prices from remote
 */
const currentPricesAndInventoryQuery = gql`
    query CurrentPricesAndInventory($ids: [ID!]!) {
        nodes(ids: $ids) {
            ... on ProductVariant {
                id
                availableForSale
                compareAtPrice {
                    amount
                    currencyCode
                }
                price {
                    amount
                    currencyCode
                }
                sku
                quantityAvailable
            }
        }
    }
`

export const getCurrentProductPricesAndInventory = async (
    productVariantIds = [] as string[],
): Promise<IShopifyProductVariant[]> => {
    const data = await client.query({
        query: currentPricesAndInventoryQuery,
        variables: {
            ids: productVariantIds,
        },
    })
    if (data.errors) {
        console.log(data.errors)
    }

    return data?.data?.nodes ?? []
}

// queries shopify product details of an array of products
const productParentOfMultipleQuery = gql`
    query ShopifyProducts ($ids: [ID!]!) {
        nodes(ids: $ids) {
            ${SHOPIFY_PRODUCT_VIA_VARIANT_QUERY}
        }
    }
`

/**
 * Returns shopify details of provided ids or empty array
 * @param productIds string[]
 * @returns shopifyProduct[]
 */
export const fetchProductParentOfMultipleFromVariant = async (productIds) => {
    const data = await client.query({
        query: productParentOfMultipleQuery,
        variables: {
            ids: productIds,
        },
    })
    if (data.errors) {
        console.log(data.errors)
    }

    return data?.data?.nodes?.map((node) => node.product) ?? []
}

/**
 * Fetches shopify details and merges with
 * medicine object
 * @param medicine IMedicine
 * @returns IMedicine
 */
export const fetchAndMergeMedicineWithShopifyProductDetails = async (
    medicine: IMedicine,
    priceExperimentVariation = priceElasticityExperimentControlVariationName,
): Promise<IMedicine> => {
    const medicineSkuIdentifier = medicine.medicineSkuIdentifier
    if (!medicineSkuIdentifier) {
        return medicine
    }

    const medicineWithShopifyDetails = { ...medicine }

    const tag = `sku-identifier-${medicineSkuIdentifier}`

    const allShopifyProductsWithGivenTags = await fetchProductsFromTags([tag])

    let assignedShopifyProduct = allShopifyProductsWithGivenTags.find((p) =>
        p.node.tags.find((tag) => tag.toLowerCase() === priceExperimentVariation.toLowerCase()),
    )?.node

    // fallback to first shopify products if
    // product with variation tag in not found
    if (!assignedShopifyProduct) {
        assignedShopifyProduct = allShopifyProductsWithGivenTags[0].node
    }

    medicineWithShopifyDetails.allAssociatedShopifyProducts = allShopifyProductsWithGivenTags

    // Merge in the Shopify product
    medicineWithShopifyDetails.shopifyProduct = assignedShopifyProduct

    // use control variation as base product
    // or the first product in the array if
    // control not found
    let baseShopifyProduct = allShopifyProductsWithGivenTags.find((p) =>
        p.node.tags.find((tag) => tag.toLowerCase() === priceElasticityExperimentControlVariationName.toLowerCase()),
    )

    if (!baseShopifyProduct) {
        baseShopifyProduct = allShopifyProductsWithGivenTags[0]
    }

    medicineWithShopifyDetails.baseShopifyProductId = baseShopifyProduct.node.id

    return medicineWithShopifyDetails
}

/**
 * Fetches and merges shopify product details of
 * each medicine in collection
 * @param medicinesCollection {items: IMedicine[]}
 * @returns Promise<{items: Imedicine[]}>
 */
export const fetchAndMergeMedicinesCollectionWithShopifyProductDetails = async (
    medicinesCollection: IMedicineCollection,
    priceExperimentVariation = priceElasticityExperimentControlVariationName,
    preview = false,
): Promise<{ items: IMedicine[] }> => {
    if (preview) {
        return fetchAndMergeMedicinesCollectionWithShopifyProductDetails_preview(
            medicinesCollection,
            priceExperimentVariation,
        )
    }

    if (!medicinesCollection.items.length) {
        return medicinesCollection
    }

    const medicinesCollectionWithShopifyDetails = deepClone(medicinesCollection) as IMedicineCollection

    const medicineSkuIdentifiers = []

    medicinesCollectionWithShopifyDetails.items.forEach((medicine) => {
        medicineSkuIdentifiers.push(medicine.medicineSkuIdentifier)
    })

    const medicineSkuIdentifierTags = medicineSkuIdentifiers.map((skuIdentifier) => `sku-identifier-${skuIdentifier}`)
    const shopifyProductDetailsOfAllMedicines = await fetchProductsFromTags(medicineSkuIdentifierTags)

    medicinesCollectionWithShopifyDetails.items.forEach((medicine) => {
        medicine.allAssociatedShopifyProducts = shopifyProductDetailsOfAllMedicines.filter((p) => {
            return p.node.tags.includes(`sku-identifier-${medicine.medicineSkuIdentifier}`)
        })

        let assignedShopifyProduct = medicine.allAssociatedShopifyProducts.find((p) => {
            return p.node.tags.find((tag) => {
                return tag.toLowerCase() === priceExperimentVariation.toLowerCase()
            })
        })?.node

        if (!assignedShopifyProduct) {
            assignedShopifyProduct = medicine.allAssociatedShopifyProducts[0].node
        }

        medicine.shopifyProduct = assignedShopifyProduct

        let baseShopifyProduct = medicine.allAssociatedShopifyProducts.find((p) =>
            p.node.tags.find(
                (tag) => tag.toLowerCase() === priceElasticityExperimentControlVariationName.toLowerCase(),
            ),
        )

        if (!baseShopifyProduct) {
            baseShopifyProduct = medicine.allAssociatedShopifyProducts[0]
        }

        medicine.baseShopifyProductId = baseShopifyProduct.node.id
    })

    return medicinesCollectionWithShopifyDetails
}

/**
 * Forms and returns a query string filter
 * for the Shopify graphql api
 * @param {tags} string[]
 * @returns string
 */
export const getShopifyProductsSearchByTagsQueryArgument = ({ tags }: { tags: string[] }): string => {
    if (!tags.length) {
        return ''
    }

    let searchByTagsQuery = ''

    tags.forEach((tag, index) => {
        if (index !== 0) {
            searchByTagsQuery += ' OR ' // keep space at the beginning and end
        }
        searchByTagsQuery += `tag:${tag}`
    })

    return searchByTagsQuery
}

/**
 * Fetches and returs Shopify Produts using tags
 * @param productIds string[]
 * @returns ProductVariant[]
 */
export const fetchProductsFromTags = async (tags: string[]) => {
    const searchQuery = getShopifyProductsSearchByTagsQueryArgument({ tags })
    const data = await client.query({
        query: gql(SHOPIFY_PRODUCTS_BY_SEARCH_QUERY),
        variables: { searchQuery },
    })

    return data.data.products.edges
}

/**
 * Fetches and merges shopify product details of
 * each medicine in collection (supports preview mode)
 * @param medicinesCollection {items: IMedicine[]}
 * @returns Promise<{items: Imedicine[]}>
 */
export const fetchAndMergeMedicinesCollectionWithShopifyProductDetails_preview = async (
    medicinesCollection: IMedicineCollection,
    priceExperimentVariation = priceElasticityExperimentControlVariationName,
): Promise<{ items: IMedicine[] }> => {
    if (!medicinesCollection.items.length) {
        return medicinesCollection
    }

    const medicinesCollectionWithShopifyDetails = deepClone(medicinesCollection) as IMedicineCollection

    const medicineSkuIdentifiers = []

    medicinesCollectionWithShopifyDetails.items.forEach((medicine) => {
        medicineSkuIdentifiers.push(medicine.medicineSkuIdentifier)
    })

    const medicineSkuIdentifierTags = medicineSkuIdentifiers.map((skuIdentifier) => `sku-identifier-${skuIdentifier}`)
    const shopifyProductDetailsOfAllMedicines = await fetchProductsFromTags(medicineSkuIdentifierTags)

    medicinesCollectionWithShopifyDetails.items.forEach((medicine) => {
        medicine.allAssociatedShopifyProducts = shopifyProductDetailsOfAllMedicines.filter((p) => {
            return p.node.tags.includes(`sku-identifier-${medicine.medicineSkuIdentifier}`)
        })

        let assignedShopifyProduct = medicine.allAssociatedShopifyProducts.find((p) => {
            return p.node.tags.find((tag) => {
                return tag.toLowerCase() === priceExperimentVariation.toLowerCase()
            })
        })?.node

        if (!assignedShopifyProduct) {
            assignedShopifyProduct = medicine.allAssociatedShopifyProducts[0]?.node
        }

        medicine.shopifyProduct = assignedShopifyProduct ?? null

        let baseShopifyProduct = medicine.allAssociatedShopifyProducts.find((p) =>
            p.node.tags.find(
                (tag) => tag.toLowerCase() === priceElasticityExperimentControlVariationName.toLowerCase(),
            ),
        )

        if (!baseShopifyProduct) {
            baseShopifyProduct = medicine.allAssociatedShopifyProducts[0]
        }

        medicine.baseShopifyProductId = baseShopifyProduct?.node?.id ?? null
    })

    return medicinesCollectionWithShopifyDetails
}

export function getLineItemType(lineItem: IShopifyOrderLineItem): LINE_ITEM_TYPE {
    const customAttributes = lineItem.customAttributes ?? lineItem.properties

    if (
        customAttributes?.find((customAttribute) => {
            const key = customAttribute['key'] ?? customAttribute['name']
            return key === 'prescription_order_id'
        })
    ) {
        return LINE_ITEM_TYPE.RX
    }

    return LINE_ITEM_TYPE.OTC
}
