import * as React from 'react'
import { IBanner } from 'interfaces/banner'

interface IBannerContext {
    showBanner: boolean
    closeBanner: () => void
    hasBannerExitedScreen: boolean
    setHasBannerExitedScreen: (hasBannerExitedScreen: boolean) => void
    banners?: IBanner[]
}

interface IProps {
    children: React.ReactNode
    banners: IBanner[]
}

const BannerContext = React.createContext<IBannerContext>(null)

export const BannerContextProvider = ({ children, banners }: IProps): React.ReactElement => {
    const [showBanner, setShowBanner] = React.useState(true)
    const [hasBannerExitedScreen, setHasBannerExitedScreen] = React.useState(false)

    // don't render banner on server
    const [activeBanners, setActiveBanners] = React.useState([])

    React.useEffect(() => {
        // filter banner on useEffect to prevent hydration bug
        const allActiveBanners = banners?.filter((banner) => {
            const now = new Date()
            return now <= new Date(banner?.activeEndDate) && now >= new Date(banner?.activeStartDate)
        })
        setActiveBanners(allActiveBanners)
    }, [banners])

    const value = React.useMemo(
        () => ({
            showBanner,
            hasBannerExitedScreen,
            closeBanner: () => setShowBanner(false),
            setHasBannerExitedScreen,
            banners: activeBanners,
        }),
        [showBanner, hasBannerExitedScreen, activeBanners],
    )

    return <BannerContext.Provider value={value}>{children}</BannerContext.Provider>
}

export const useBannerContext = (): IBannerContext => {
    const bannerContext = React.useContext(BannerContext)

    if (typeof bannerContext === 'undefined') {
        throw new Error('You cannot use useBannerContext outside BannerContextProvider')
    }

    return bannerContext
}

export default BannerContextProvider
