import * as React from 'react'
import { IImage } from 'interfaces/media'
import DeleteIcon from 'icons/delete-icon'
import globalManifest from 'data/global-manifest.json'
import { getFormattedNumberWithCurrency } from '@/lib/util/currency'

interface IProps {
    image: IImage
    title: React.ReactNode
    price: number
    currency?: string
    compareAtPrice?: React.ReactNode
    onRemove: () => void
    body: React.ReactNode
    loading?: boolean
    isAvailable: boolean
}

const { resources: globalUIResources } = globalManifest

export default function CartLineItem({
    image,
    title,
    price,
    currency,
    onRemove,
    loading,
    body,
    compareAtPrice,
    isAvailable,
}: IProps) {
    return (
        <div className="flex flex-wrap xs:flex-nowrap justify-between items-start space-x-2" data-cy="cart-item">
            <div className="flex w-full items-start">
                {/* thumbnail */}
                <picture>
                    {/* webp */}
                    <source type="image/webp" srcSet={`${image.url}?w=200&fit=thumb&fm=webp`} />
                    {/* jpeg */}
                    <source type="image/jpeg" srcSet={`${image.url}?w=200&fit=thumb&fm=jpg`} />
                    <img
                        src={`${image.url}?w=200&fit=thumb`}
                        alt={image.title}
                        height={image.height}
                        width={image.width}
                        className="rounded shrink-0 w-[70px] h-[70px] object-cover bg-blue-rx"
                        loading="lazy"
                    />
                </picture>

                {/* product details */}
                <div className="ml-3.5 w-0 grow flex flex-col justify-between items-start self-center">
                    <div className="w-full flex justify-between items-start">
                        <div className="w-full flex flex-col">
                            <div className="flex justify-between items-start space-x-2.5">
                                <div className="text-lg font-medium leading-120% -tracking-9 truncate">{title}</div>
                                {/* remove button */}
                                <button
                                    aria-label={globalUIResources['ariaLabelRemoveItem'].value as string}
                                    onClick={onRemove}
                                >
                                    <DeleteIcon />
                                </button>
                            </div>
                            <div className="flex justify-between items-end space-x-2.5 mt-3">
                                <div className="text-sm leading-140% -tracking-7">
                                    {body}
                                    {!isAvailable && (
                                        <div
                                            data-cy="cart-item-unavailable"
                                            className="bg-transparent text-center text-xs py-0.5 px-3 border border-gray rounded-full"
                                        >
                                            {globalUIResources['outOfStockButtonLabel'].value}
                                        </div>
                                    )}
                                </div>
                                {/* price */}
                                {loading ? (
                                    <div className="pulse w-12 h-5 bg-gray-medium rounded-5" />
                                ) : (
                                    <div className="text-sm font-medium leading-140% tracking-7 flex">
                                        {compareAtPrice && (
                                            <s
                                                data-cy="compare-price"
                                                className="text-gray-darker mr-2 -tracking-7 font-medium"
                                            >
                                                {compareAtPrice}
                                            </s>
                                        )}
                                        {!isAvailable ? (
                                            <s suppressHydrationWarning>
                                                {getFormattedNumberWithCurrency(price, currency)}
                                            </s>
                                        ) : (
                                            <div data-cy="price" suppressHydrationWarning={true}>
                                                {getFormattedNumberWithCurrency(price, currency)}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
