import * as React from 'react'
import LinkBlock from 'components/link-block'
import { ILink } from 'interfaces/link'
import styles from './style.module.css'

interface IProps {
    links: ILink[]
    handleLinkClick?: () => void
}

export const NavigationBlocks = ({ links, handleLinkClick }: IProps): React.ReactElement => {
    if (!links?.length) {
        return null
    }

    return (
        <div className="-ml-3.5 -mt-3.5 flex flex-wrap lg:flex-nowrap">
            {links.map((l) => {
                const link = l as ILink
                return (
                    <div key={link.sys.id} className={`ml-3.5 mt-3.5 ${styles['link-block-wrapper']}`}>
                        <LinkBlock link={link} onClick={() => handleLinkClick?.()} />
                    </div>
                )
            })}
        </div>
    )
}
