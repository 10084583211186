import * as React from 'react'
import { useFeature } from '@growthbook/growthbook-react'
import { useOTCCartContext } from 'context/otc-cart-context'
import { COUPON_FEATURE_VARIATIONS, getCouponFromFeature } from 'lib/util/coupon'
import Coupon from './coupon'
import styles from './style.module.css'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { IconButton } from 'components/buttons/button'
import globalManifest from 'data/global-manifest.json'
import ArrowRightIcon from 'icons/arrow-right'
import { colors } from 'theme.cjs'

const { resources: globalUIResources } = globalManifest ?? {}

const cartPromoIntersectionOptions: IntersectionObserverInit = {
    threshold: 0.75,
}

const CartPromoContainer = ({
    children,
    setActivePromoIndex,
    promoIndex,
}: {
    children: React.ReactNode
    setActivePromoIndex: React.Dispatch<React.SetStateAction<number>>
    promoIndex: number
}) => {
    const [containerEl, setContainerEl] = React.useState<HTMLDivElement>()

    const containerElRef = React.useCallback((node) => {
        if (node !== null) {
            setContainerEl(node)
        }
    }, [])

    const { entry } = useIntersectionObserver({
        element: containerEl,
        options: cartPromoIntersectionOptions,
    })

    React.useEffect(() => {
        if (entry?.isIntersecting) {
            setActivePromoIndex(promoIndex)
        }
    }, [entry?.isIntersecting, setActivePromoIndex, promoIndex])

    return (
        <div className="h-full" ref={containerElRef}>
            {children}
        </div>
    )
}

export const CartPromotions = (): React.ReactElement => {
    const { coupon: couponInCart } = useOTCCartContext()

    // Decide which promos to show to a customer (right now this is an experiment)
    const cartFooterPromoFeature = useFeature('cart-footer-promo')

    const couponDetails = getCouponFromFeature(cartFooterPromoFeature?.value)

    const [activePromoIndex, setActivePromoIndex] = React.useState(0)
    const containerElRef = React.useRef<HTMLDivElement>()

    const showCoupon = couponDetails.name === COUPON_FEATURE_VARIATIONS.coupon && !couponInCart

    const promos = []

    if (showCoupon) {
        promos.push({
            key: 'coupon',
            element: <Coupon coupon={couponDetails} />,
        })
    }

    // adds support for drag to scroll with momentum
    // momentum credit: https://codepen.io/loxks/details/KKpVvVW
    React.useEffect(() => {
        // since the container is scrollable touch devices
        // have default support for drag
        const isTouchDevice = window.matchMedia(`(pointer: coarse)`).matches

        if (!containerElRef.current || promos.length < 2 || isTouchDevice) {
            return
        }

        let isMouseDown = false
        let velocityX = 0
        let animationFrameId

        const startMomentumScroll = () => {
            containerEl.scrollLeft += velocityX
            // decelerate on every frame
            velocityX *= 0.95

            const hasScrolledToEnd =
                containerEl.scrollLeft <= 0 ||
                containerEl.scrollLeft + containerEl.offsetWidth >= containerEl.scrollWidth

            if (Math.abs(velocityX) > 0.5 && !hasScrolledToEnd) {
                animationFrameId = requestAnimationFrame(startMomentumScroll)
            }
        }

        const cancelMomentumScroll = () => {
            cancelAnimationFrame(animationFrameId)
        }

        const containerEl = containerElRef.current

        const stopDrag = () => {
            if (isMouseDown) {
                startMomentumScroll()
                isMouseDown = false
            }
        }

        const mouseDownListener = () => {
            isMouseDown = true
            cancelMomentumScroll()
        }

        const mouseUpListener = () => {
            stopDrag()
        }

        const mouseLeaveListener = () => {
            stopDrag()
        }

        const mouseMoveListener = (e: MouseEvent) => {
            e.preventDefault()
            if (isMouseDown) {
                const newPosX = e.movementX * -1 * 1.5 // speed up drag by factor of 1.5
                const prevScrollLeft = containerEl.scrollLeft
                containerEl.scrollLeft += newPosX
                velocityX = containerEl.scrollLeft - prevScrollLeft
            }
        }

        containerEl.addEventListener('mousedown', mouseDownListener)
        containerEl.addEventListener('mouseup', mouseUpListener)
        containerEl.addEventListener('mousemove', mouseMoveListener)
        containerEl.addEventListener('mouseleave', mouseLeaveListener)

        return () => {
            containerEl.removeEventListener('mousedown', mouseDownListener)
            containerEl.removeEventListener('mouseup', mouseUpListener)
            containerEl.removeEventListener('mousemove', mouseMoveListener)
            containerEl.removeEventListener('mouseleave', mouseLeaveListener)

            cancelAnimationFrame(animationFrameId)
        }
    }, [promos.length])

    const scrollToPromo = (promoIndex: number) => {
        if (containerElRef.current) {
            const promo = containerElRef.current.children[promoIndex]

            promo?.scrollIntoView({ inline: 'center', behavior: 'smooth' })
        }
    }

    if (promos.length === 0) {
        return null
    }

    return (
        <div className={`pt-3.75 relative ${promos.length > 1 ? 'pb-0.5' : 'pb-3.75'} border-t border-gray-lighter `}>
            <div
                ref={containerElRef}
                className={`flex flex-row items-stretch w-full overflow-auto ${styles['cart-promotions-container']}`}
            >
                {promos.map((promo, index) => (
                    <div key={promo.key} className="first:pl-3.75 pr-3.75 grow shrink-0 first:ml-0 w-[90%] lg:w-[85%]">
                        <CartPromoContainer setActivePromoIndex={setActivePromoIndex} promoIndex={index}>
                            {promo.element}
                        </CartPromoContainer>
                    </div>
                ))}
            </div>

            {promos.length > 1 && (
                <div className="mt-0.5 flex justify-center">
                    {promos.map((promo, index) => (
                        <IconButton
                            label={(globalUIResources?.['sliderDotButtonLabel']?.value as string)?.replace(
                                '{index}',
                                index + 1 + '',
                            )}
                            onClick={() => scrollToPromo(index)}
                            className="p-1.5 bg-transparent"
                            key={promo.key}
                        >
                            <div
                                className={`w-1.5 h-1.5 rounded-full ml-1 first:ml-0 ${
                                    activePromoIndex === index ? 'bg-black' : 'bg-gray-lighter'
                                }`}
                            />
                        </IconButton>
                    ))}
                </div>
            )}

            {/* navigation arrow icons */}
            {promos.length > 1 && (
                <>
                    <IconButton
                        label={globalUIResources?.['cartPromotionLeftArrowNavigation']?.value as string}
                        aria-hidden={activePromoIndex === 0}
                        onClick={(e) => {
                            e.stopPropagation()
                            scrollToPromo(activePromoIndex - 1)
                        }}
                        className={`hidden md:block absolute left-0 top-0 bg-black/50 hover:bg-black/60 sm:px-4 h-full rounded-sm rounded-bl-none rounded-tl-none transition-opacity ${
                            activePromoIndex === 0 ? 'opacity-0 cursor-default' : 'opacity-100'
                        }`}
                    >
                        <ArrowRightIcon
                            height="14"
                            width="7"
                            className="rotate-180"
                            stroke={colors.white}
                            strokeWidth={2}
                        />
                    </IconButton>
                    <IconButton
                        label={globalUIResources?.['cartPromotionRightArrowNavigation']?.value as string}
                        aria-hidden={activePromoIndex === promos.length - 1}
                        onClick={(e) => {
                            e.stopPropagation()
                            scrollToPromo(activePromoIndex + 1)
                        }}
                        className={`hidden md:block absolute right-0 top-0 bg-black/50 hover:bg-black/60 sm:px-4 h-full rounded-sm rounded-tr-none rounded-br-none transition-opacity ${
                            activePromoIndex === promos.length - 1 ? 'opacity-0 cursor-default' : 'opacity-100'
                        }`}
                    >
                        <ArrowRightIcon height="14" width="7" stroke={colors.white} strokeWidth={2} />
                    </IconButton>
                </>
            )}
        </div>
    )
}
