import * as React from 'react'
import { IUiResource } from 'interfaces/ui-resource'
import globalManifest from 'data/global-manifest.json'
import { IPlasticQuantity } from 'lib/util/environmental-impact'

interface IProps {
    plasticEliminatedResource: IUiResource
    carbonEmissionResource: IUiResource
    plasticEliminated: IPlasticQuantity
    impactTextAlignment?: 'right' | 'left'
}

const { resources: globalUIResources } = globalManifest

const plasticEliminatedLbsTemplateString = globalUIResources['cartPlasticEliminatedLbsTemplate'].value
const plasticEliminatedOzTemplateString = globalUIResources['cartPlasticEliminatedOzTemplate'].value

export const EnvironmentalImpact = ({
    plasticEliminatedResource,
    carbonEmissionResource,
    plasticEliminated,
    impactTextAlignment = 'right',
}: IProps): React.ReactElement => {
    const plasticEliminatedIcon = plasticEliminatedResource.media
    const plasticEliminatedLabel = plasticEliminatedResource.value

    const carbonEmissionIcon = carbonEmissionResource.media
    const carbonEmissionLabel = carbonEmissionResource.value

    let plasticEliminatedString = ''

    if (plasticEliminated.lbs) {
        plasticEliminatedString =
            plasticEliminatedLbsTemplateString?.replace('{lbs_amount}', plasticEliminated.lbs + '') ?? ''
    }

    if (plasticEliminated.oz) {
        // add space in between lbs and oz
        if (plasticEliminated.lbs) {
            plasticEliminatedString += ' '
        }

        plasticEliminatedString +=
            plasticEliminatedOzTemplateString?.replace('{oz_amount}', plasticEliminated.oz + '') ?? ''
    }

    if (!plasticEliminatedString) {
        plasticEliminatedString = '0'
    }

    return (
        <div>
            <div
                className={`flex items-center ${
                    impactTextAlignment === 'right' ? 'justify-between' : ''
                } first:mt-0 mt-2.5 text-sm`}
            >
                <div className="flex items-center">
                    {plasticEliminatedIcon?.url && (
                        <picture>
                            {/* svg */}
                            {plasticEliminatedIcon.url.endsWith('.svg') && (
                                <source type="image/svg+xml" srcSet={plasticEliminatedIcon.url} />
                            )}

                            {/* webp */}
                            <source type="image/webp" srcSet={plasticEliminatedIcon.url + '?fm=webp&w=50'} />

                            {/* jpeg */}
                            <source type="image/jpeg" srcSet={plasticEliminatedIcon.url + '?fm=jpg&w=50'} />

                            <img
                                src={plasticEliminatedIcon.url + '?fm=jpg&w=50'}
                                height={plasticEliminatedIcon.height}
                                width={plasticEliminatedIcon.width}
                                alt={plasticEliminatedIcon.title}
                                className="h-5 w-5 object-cover"
                                loading="lazy"
                            />
                        </picture>
                    )}
                    <span className="ml-2">{plasticEliminatedLabel}</span>
                </div>
                <span className="text-allergyRelief-text font-bold ml-1">{plasticEliminatedString}</span>
            </div>
            <div className="flex items-center justify-between mt-2.5 text-sm">
                <div className="flex items-center">
                    {carbonEmissionIcon?.url && (
                        <picture>
                            {/* svg */}
                            {carbonEmissionIcon.url.endsWith('.svg') && (
                                <source type="image/svg+xml" srcSet={carbonEmissionIcon.url} />
                            )}

                            {/* webp */}
                            <source type="image/webp" srcSet={carbonEmissionIcon.url + '?fm=webp&w=50'} />

                            {/* jpeg */}
                            <source type="image/jpeg" srcSet={carbonEmissionIcon.url + '?fm=jpg&w=50'} />

                            <img
                                src={carbonEmissionIcon.url + '?fm=jpg&w=50'}
                                height={carbonEmissionIcon.height}
                                width={carbonEmissionIcon.width}
                                alt={carbonEmissionIcon.title}
                                className="h-5 w-5 object-cover"
                                loading="lazy"
                            />
                        </picture>
                    )}
                    <span className="ml-2">{carbonEmissionLabel}</span>
                </div>
            </div>
        </div>
    )
}
