import * as React from 'react'
import { ILink } from 'interfaces/link'
import Link from 'next/link'
import { wordsInsideParenthesisRegexp } from 'lib/util/regexps'
import { useHelpSidebar } from 'context/help-sidebar-context'
import { usePharmacyCardPopup } from 'context/pharmacy-card-popup-context'
import {
    helpPanelNavigationMenuItemInternalName,
    pharmacyCardNavigationMenuItemInternalName,
} from 'constants/contentful'

interface IProps {
    links: ILink[]
    handleLinkClick?: () => void
}

export const NavigationVerticalList = ({ links, handleLinkClick }: IProps): React.ReactElement => {
    const { open: openHelp } = useHelpSidebar()
    const { open: openPharmcyCardPopup } = usePharmacyCardPopup()

    if (!links?.length) {
        return null
    }

    return (
        <ul>
            {links.map((link) => {
                const { name, href, sys, associatedColors } = link

                const matchedWord = name.match(wordsInsideParenthesisRegexp)
                const templateSplit: React.ReactNode[] = name.split(wordsInsideParenthesisRegexp)

                const matchedWordWithParenthesis = matchedWord?.[0]

                const nameElement = [
                    templateSplit[0],
                    matchedWordWithParenthesis ? (
                        <span key="word-inside-parenthesis" className="text-gray">
                            {matchedWordWithParenthesis}
                        </span>
                    ) : null,
                ]

                if (link.internalName === helpPanelNavigationMenuItemInternalName) {
                    return (
                        <li key={sys.id}>
                            <button
                                onClick={() => {
                                    openHelp()
                                    handleLinkClick?.()
                                }}
                                className="text-xbase font-medium mt-2.5 lg:mt-3.5 flex items-center"
                            >
                                {nameElement}
                            </button>
                        </li>
                    )
                }

                if (link.internalName === pharmacyCardNavigationMenuItemInternalName) {
                    return (
                        <li key={sys.id}>
                            <button
                                onClick={() => {
                                    openPharmcyCardPopup()
                                }}
                                className="text-xbase font-medium mt-2.5 lg:mt-3.5 flex items-center"
                            >
                                {nameElement}
                            </button>
                        </li>
                    )
                }

                return (
                    <li key={sys.id}>
                        <Link
                            href={href}
                            onClick={() => handleLinkClick?.()}
                            className="text-xbase font-medium mt-2.5 lg:mt-3.5 can-hover:hover:underline flex items-center"
                        >
                            {nameElement}
                            <div
                                className="h-2 w-2 rounded-full ml-2"
                                style={{ backgroundColor: associatedColors?.split(',')?.[0] }}
                            />
                        </Link>
                    </li>
                )
            })}
        </ul>
    )
}
