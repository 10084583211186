import { IAddress } from 'interfaces/prescriptions/address'
import { fetchBackend } from './backend'
import { IProfile } from 'interfaces/profile'

export interface ICustomerResponse {
    id: string
    first_name: string
    last_name: string
    phone_number: string
    allergies: string[]
    addresses: IAddress[]
    family_accounts: IProfile[]
}

const cache = {}

export const fetchCustomer = async (
    authToken: string,
    customerId: string,
): Promise<{ data: ICustomerResponse; error: boolean }> => {
    const cacheKey = customerId
    const cachedCustomer = cache[JSON.stringify(cacheKey)]
    if (cachedCustomer) {
        return { data: cachedCustomer, error: false }
    }

    const resp = await fetchBackend(`/api/customer/${customerId}`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    })

    if (resp.ok) {
        const data = await resp.json()
        cache[JSON.stringify(cacheKey)] = data
        return { data, error: false }
    } else {
        return {
            data: undefined,
            error: !resp.ok,
        }
    }
}
