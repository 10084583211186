import * as React from 'react'
import { ILink } from 'interfaces/link'
import Link from 'next/link'

interface IProps {
    links: ILink[]
    handleLinkClick?: () => void
}

export const NavigationHorizontalList = ({ links, handleLinkClick }: IProps): React.ReactElement => {
    if (!links.length) {
        return null
    }

    return (
        <ul className="flex justify-between">
            {links.map((link) => (
                <li key={link.sys?.id}>
                    <Link
                        key={link.sys?.id}
                        href={link.href}
                        onClick={() => handleLinkClick?.()}
                        className="text-xbase font-bold"
                    >
                        {link.name}
                    </Link>
                </li>
            ))}
        </ul>
    )
}
