/* eslint-disable react/display-name */
import * as React from 'react'
import { IconButton, PlainButton } from 'components/buttons/button'
import CrossIcon from 'icons/CrossIcon'
import { useBannerContext } from 'context/banner-context'
import styles from './style.module.css'
import Modal from 'components/modal/modal'
import { DIRECTION_VERTICAL } from 'enum/direction'
import NotificationSignupForm from 'components/notification-signup-form/notification-signup-form'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import Link from 'next/link'
import { IBanner } from 'interfaces/banner'
import globalManifest from 'data/global-manifest.json'

interface IProps {
    banner: IBanner
}

const { resources: globalUIResources } = globalManifest ?? {}

const BANNER_CTA_ACTIONS = {
    MODAL_SIGNUP_FLOW: 'modalSignupFlow',
    LINK: 'link',
}

const getRenderOptions = (links) => {
    return {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (_node, children) => <span className="text-xsm">{children}</span>,
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const asset = links?.assets?.block?.find((a) => a?.sys?.id === node?.data?.target?.sys?.id)

                return (
                    <img
                        className="ml-0.5 mt-0 lg:mt-1 h-6 w-auto float-right"
                        src={`${asset?.url}?fit=thumb&w=50`}
                        alt={asset?.title}
                        height={asset?.height}
                        width={asset?.width}
                    />
                )
            },
        },
    }
}

const leftRichTextRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <span className="tracking-widest">{children}</span>,
}

const ctaLinkRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <span>{children}</span>,
}

const Banner = ({ banner }: IProps): React.ReactElement => {
    const { closeBanner, showBanner } = useBannerContext()
    const [showModal, setShowModal] = React.useState(false)

    const bannerElRef = React.useRef<HTMLDivElement>()

    React.useEffect(() => {
        const bannerEl = bannerElRef.current

        return () => {
            document.documentElement.style.setProperty('--bannerHeight', bannerEl?.clientHeight + 'px')
        }
    }, [])

    React.useEffect(() => {
        document.documentElement.style.setProperty('--bannerHeight', bannerElRef.current?.clientHeight + 'px')
    }, [showBanner, banner])

    if (!banner) {
        return null
    }

    const { leftRichText, centerText, centerTextMobile, ctaRichText, ctaAction, ctaLink, backgroundColor, textColor } =
        banner

    return (
        <>
            <div
                ref={bannerElRef}
                className="p-2 flex text-xsm items-center relative z-20"
                style={{ background: backgroundColor, color: textColor }}
            >
                <div className="hidden lg:inline flex-1">
                    {leftRichText?.json && (
                        <ContentfulRichText
                            content={leftRichText.json}
                            renderNode={leftRichTextRenderNode}
                            className="left-text"
                        />
                    )}
                </div>

                {ctaAction === BANNER_CTA_ACTIONS.MODAL_SIGNUP_FLOW && centerTextMobile?.json && (
                    <button
                        onClick={() => setShowModal(true)}
                        className="inline-flex justify-center lg:hidden ml-6 flex-1 text-center text-inherit"
                    >
                        <ContentfulRichText
                            className="inline"
                            content={centerTextMobile.json}
                            renderOptions={getRenderOptions(centerTextMobile.links)}
                        />
                    </button>
                )}

                {ctaAction === BANNER_CTA_ACTIONS.LINK && centerTextMobile?.json && (
                    <Link
                        href={ctaLink.href}
                        className="inline-flex justify-center font-bold lg:hidden ml-6 flex-1 text-center"
                    >
                        <ContentfulRichText
                            className="flex items-center"
                            content={centerTextMobile.json}
                            renderOptions={getRenderOptions(centerTextMobile.links)}
                        />
                    </Link>
                )}
                <span className="hidden lg:inline-flex tracking-wide">
                    {centerText?.json && (
                        <ContentfulRichText
                            content={centerText.json}
                            renderOptions={getRenderOptions(centerText.links)}
                        />
                    )}
                </span>

                <div className="lg:flex-1 flex justify-end items-center">
                    {ctaAction === BANNER_CTA_ACTIONS.MODAL_SIGNUP_FLOW && ctaRichText?.json && (
                        <PlainButton
                            onClick={() => setShowModal(true)}
                            className="hidden text-sm lg:inline p-0 tracking-wide font-normal text-inherit"
                        >
                            <ContentfulRichText
                                className="text-sm p-0 tracking-wide"
                                content={ctaRichText.json}
                                renderNode={ctaLinkRenderNode}
                            />
                        </PlainButton>
                    )}
                    {ctaAction === BANNER_CTA_ACTIONS.LINK && ctaRichText?.json && (
                        <Link href={ctaLink.href} className="hidden lg:inline">
                            <ContentfulRichText
                                className="text-sm p-0 tracking-wide"
                                content={ctaRichText?.json}
                                renderNode={ctaLinkRenderNode}
                            />
                        </Link>
                    )}

                    {/* close banner button */}
                    <IconButton
                        label={globalUIResources?.['closeIconButtonLabel']?.value as string}
                        onClick={closeBanner}
                        className="lg:ml-6 p-0"
                    >
                        <div className="bg-white h-6 w-6 flex justify-center items-center rounded-full">
                            <CrossIcon />
                        </div>
                    </IconButton>
                </div>
            </div>
            {showModal && (
                <Modal
                    position={DIRECTION_VERTICAL.TOP}
                    className={`shadow-cabinet-auth ${styles['modal']}`}
                    closeModal={() => setShowModal(false)}
                    modalWrapperClassName={styles['modal-wrapper']}
                    radiusClassName="rounded"
                >
                    <NotificationSignupForm />
                </Modal>
            )}
        </>
    )
}

export default Banner
