import * as React from 'react'

export default function DeleteIcon({ width = 22, height = 22, className = '', fill = '#767676', stroke = '#767676' }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" className={className}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m4.934 7.836.79 9.488c.08.967.89 1.711 1.86 1.711h6.832c.97 0 1.78-.744 1.86-1.711l.79-9.488H4.935Zm1.014.933.706 8.478c.04.483.445.855.93.855h6.832c.485 0 .89-.372.93-.855l.706-8.478H5.948Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.133 5.97H5.867a.933.933 0 0 0-.934.933v.933h12.133v-.933a.933.933 0 0 0-.933-.934ZM5.867 5.035C4.836 5.036 4 5.872 4 6.903v1.866h14V6.903c0-1.031-.836-1.867-1.867-1.867H5.867Z"
                fill={fill}
            />
            <mask id="a" fill="#fff">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 4.103c-1.03 0-1.867.836-1.867 1.867H8.2a2.8 2.8 0 1 1 5.6 0h-.933c0-1.031-.836-1.867-1.867-1.867Z"
                />
            </mask>
            <path
                d="M9.133 5.97v1.077h1.077V5.97H9.133Zm-.933 0H7.123v1.077H8.2V5.97Zm5.6 0v1.077h1.077V5.97H13.8Zm-.933 0H11.79v1.077h1.077V5.97Zm-2.657 0a.79.79 0 0 1 .79-.79V3.026A2.943 2.943 0 0 0 8.057 5.97h2.153ZM8.2 7.047h.934V4.893H8.2v2.154ZM11 2.093A3.877 3.877 0 0 0 7.123 5.97h2.154c0-.952.772-1.723 1.723-1.723V2.093Zm3.877 3.877A3.877 3.877 0 0 0 11 2.093v2.154c.952 0 1.723.771 1.723 1.723h2.154Zm-2.01 1.077h.933V4.893h-.933v2.154ZM11 5.18a.79.79 0 0 1 .79.79h2.154A2.944 2.944 0 0 0 11 3.026V5.18Z"
                fill={fill}
                mask="url(#a)"
            />
            <path stroke={stroke} strokeWidth=".5" d="M9.25 10.25h.5v5.5h-.5zM12.25 10.25h.5v5.5h-.5z" />
        </svg>
    )
}
