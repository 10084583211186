import * as React from 'react'
import { popupSeenLSKey } from 'misc-variables'
import { getItemFromLS, setItemInLS } from 'lib/util/storage'
import globalManifest from 'data/global-manifest.json'
import { IPageSectionForm } from 'interfaces/page-section-form'
import { IUiResource } from 'interfaces/ui-resource'

interface IEmailCapturePopup {
    form: IPageSectionForm
    brandIcons: IUiResource[]
}

interface IEmailCapturePopupContext {
    open: boolean
    displayed: boolean
    openPopup: () => void
    closePopup: () => void
    setDisplayed: React.Dispatch<React.SetStateAction<boolean>>
    popup: {
        form: IPageSectionForm
        brandIcons: IUiResource[]
    }
    setPopup: React.Dispatch<React.SetStateAction<IEmailCapturePopup>>
    disabled?: boolean // don't show popup
    setDisabled?: React.Dispatch<React.SetStateAction<boolean>>
}

interface IProps {
    children: React.ReactNode
}

const { emailCaptureModalForm: globalEmailCaptureForm, brandIcons: globalBrandIcons } = globalManifest

const EmailCapturePopupContext = React.createContext<IEmailCapturePopupContext>(undefined)

const EmailCapturePopupContextProvider = ({ children }: IProps): React.ReactElement => {
    const [open, setOpen] = React.useState(false)

    // stores if the popup has been shown to the user
    // on page load fetch the state from local storage
    const [displayed, setDisplayed] = React.useState(() => {
        if (typeof window === 'undefined') {
            return false
        }

        return getItemFromLS(popupSeenLSKey) === 'true'
    })

    const [popup, setPopup] = React.useState<IEmailCapturePopup>({
        // @ts-ignore - structure of rich text does not match the interface
        form: globalEmailCaptureForm,
        brandIcons: globalBrandIcons,
    })

    const [disabled, setDisabled] = React.useState(false)

    const value = React.useMemo<IEmailCapturePopupContext>(
        () => ({
            open,
            displayed,
            openPopup: () => setOpen(true),
            closePopup: () => {
                setOpen(false)
                setItemInLS(popupSeenLSKey, 'true')
            },
            setDisplayed,
            setPopup,
            popup,
            disabled,
            setDisabled,
        }),
        [open, displayed, popup, disabled],
    )

    return <EmailCapturePopupContext.Provider value={value}>{children}</EmailCapturePopupContext.Provider>
}

export const useEmailCapturePopupContext = (): IEmailCapturePopupContext => {
    const context = React.useContext(EmailCapturePopupContext)

    if (typeof context === 'undefined') {
        throw Error('You cannot use useEmailCapturePopupContext outside EmailCapturePopupContextProvider')
    }

    return context
}

export default EmailCapturePopupContextProvider
