import { colors } from 'theme.cjs'

const ProfileIcon = ({
    stroke = colors.mineshaft,
    height = 21,
    width = 22,
    className = '',
} = {}): React.ReactElement => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" className={className}>
        <path d="M21 21a6 6 0 0 0-6-6H7a6 6 0 0 0-6 6" stroke={stroke} strokeWidth="2" strokeLinejoin="round" />
        <circle cx="11" cy="6" r="5" stroke={stroke} strokeWidth="2" strokeLinejoin="round" />
    </svg>
)

export default ProfileIcon
