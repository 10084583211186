import * as React from 'react'
import Accordion from 'components/accordion'
import MinusIcon from 'icons/minus'
import PlusIcon from 'icons/plus'
import { colors } from 'theme.cjs'
import globalManifest from 'data/global-manifest.json'
import { useOTCCartContext } from 'context/otc-cart-context'
import { getLineItemsFromKitLineItem } from 'lib/util/cart'
import { getPlasticYearlySavingsForOrder } from 'lib/util/environmental-impact'
import { EnvironmentalImpact } from 'components/environmental-impact/environmental-impact'

const { resources: globalUIResources } = globalManifest ?? {}

const plasticEliminatedLbsTemplateString = globalUIResources?.['cartPlasticEliminatedLbsTemplate']?.value
const plasticEliminatedOzTemplateString = globalUIResources?.['cartPlasticEliminatedOzTemplate']?.value

const CartAccordionHeader = ({ toggle, item, activeTabs }): React.ReactElement => {
    return (
        <button
            className="text-xsm font-bold w-full flex justify-between items-center"
            onClick={() => toggle(item.key)}
        >
            <span>{globalUIResources?.['cartImpactAccordionLabel']?.value}</span>
            {activeTabs.includes(item.key) ? (
                <MinusIcon fill={colors.mineshaft} width={10} />
            ) : (
                <PlusIcon fill={colors.mineshaft} width={10} height={10} />
            )}
        </button>
    )
}

const CartEnvironmentalImpact = (): React.ReactElement => {
    const { cartLineItems, kitLineItems } = useOTCCartContext()

    const allLineItems = [...cartLineItems]

    kitLineItems.map((product) => {
        allLineItems.push(...getLineItemsFromKitLineItem(product))
    })

    const plasticEliminated = getPlasticYearlySavingsForOrder(allLineItems)

    let plasticEliminatedString = ''

    if (plasticEliminated.lbs) {
        plasticEliminatedString =
            plasticEliminatedLbsTemplateString?.replace('{lbs_amount}', plasticEliminated.lbs + '') ?? ''
    }

    if (plasticEliminated.oz) {
        // add space in between lbs and oz
        if (plasticEliminated.lbs) {
            plasticEliminatedString += ' '
        }

        plasticEliminatedString +=
            plasticEliminatedOzTemplateString?.replace('{oz_amount}', plasticEliminated.oz + '') ?? ''
    }

    if (!plasticEliminatedString) {
        plasticEliminatedString = '0'
    }

    return (
        <Accordion
            customHeader={CartAccordionHeader}
            items={[
                {
                    key: 'cart-impact',
                    title: globalUIResources['cartImpactAccordionLabel'].value as string,
                    content: (
                        <div className="mt-2.5">
                            <EnvironmentalImpact
                                carbonEmissionResource={globalUIResources['cartCarbonEmissionsLabel']}
                                plasticEliminatedResource={globalUIResources['cartPlasticEliminatedLabel']}
                                plasticEliminated={plasticEliminated}
                            />
                        </div>
                    ),
                },
            ]}
            bottomBorder={false}
        />
    )
}

export default CartEnvironmentalImpact
