import { fetchWithTimeout } from '../util/fetch'

/**
 * Same as native fetch except this prefixes all endpoints
 * with the backend host
 * @param endpoint Must begin with '/'
 */
export function fetchBackend(endpoint: string, init?: RequestInit): Promise<Response> {
    const url = process.env.BACKEND_ENDPOINT + endpoint
    return fetchWithTimeout(url, init, 15000)
}
