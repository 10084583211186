import { useAuth } from 'components/auth/auth'
import { IconButton } from 'components/buttons/button'
import ProfileIcon from 'icons/profile-icon'
import { colors } from 'theme.cjs'
import Link from 'next/link'
import globalManifest from 'data/global-manifest.json'
import { accountPagePath } from 'constants/url'
import { useRouter } from 'next/router'
import { ProfileSelector } from 'components/profile-selector'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'

const { resources } = globalManifest

interface IProps {
    light?: boolean
}

/**
 * This button lives in the header.
 */
export const MyCabinetButton = ({ light }: IProps): React.ReactElement => {
    const { isAuthenticated, loginWithRedirect, loading } = useAuth()
    const { pathname } = useRouter()
    const isMobileScreen = useMobileScreenDetection()

    const login = () => {
        if (!loading) {
            loginWithRedirect({ appState: { returnTo: accountPagePath } })
        }
    }

    if (!isAuthenticated) {
        return (
            <IconButton label={resources['logInButton'].value} onClick={login} data-cy="account-button">
                <ProfileIcon stroke={light ? colors.white : colors.mineshaft} />
            </IconButton>
        )
    }

    if (pathname !== accountPagePath) {
        return (
            <Link
                href="/account"
                className="button button-icon"
                aria-label={resources?.openProfileSidebarIconButtonLabel?.value}
                data-cy="account-link"
            >
                <ProfileIcon stroke={light ? colors.white : colors.mineshaft} />
            </Link>
        )
    }

    // profile toggle is in sidebar in mobile
    if (isMobileScreen) {
        return null
    }

    return <ProfileSelector />
}
