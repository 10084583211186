import { colors } from 'theme.cjs'

const CartIcon = ({
    height = '24px',
    width = '25px',
    fill = colors.mineshaft,
    className = '',
    badge = undefined,
} = {}): React.ReactElement => (
    <div className={`relative ${className}`}>
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
            <path
                d="M7.88 15.362 4.872 3.243a.76.76 0 0 0-.735-.582H1.624a.828.828 0 0 1-.82-.828c0-.454.37-.83.82-.83h2.514c1.11 0 2.069.767 2.346 1.863l1.395 12.496Zm0 0c.276 1.096 1.234 1.862 2.345 1.862h9.833a2.41 2.41 0 0 0 2.347-1.863l1.848-7.68a2.362 2.362 0 0 0-.454-2.068 2.417 2.417 0 0 0-1.892-.943H6.942l-.458-1.804 1.395 12.496Zm14.762-8.06v.004l-1.849 7.679a.76.76 0 0 1-.735.582h-9.833a.76.76 0 0 1-.734-.582L7.364 6.352h14.543c.228 0 .458.116.596.302.14.189.207.42.139.649ZM10.743 18.11c-1.436 0-2.619 1.198-2.619 2.65 0 1.451 1.183 2.649 2.619 2.649 1.436 0 2.618-1.198 2.618-2.65 0-1.451-1.182-2.649-2.618-2.649Zm0 3.616a.95.95 0 0 1-.955-.967.95.95 0 0 1 .955-.966.95.95 0 0 1 .954.966c0 .519-.443.967-.954.967ZM21.839 20.76v-.002c-.025-1.45-1.182-2.648-2.619-2.648-1.436 0-2.618 1.198-2.618 2.65 0 1.451 1.182 2.649 2.618 2.649s2.619-1.198 2.619-2.65Zm-2.619.966a.95.95 0 0 1-.954-.967.95.95 0 0 1 .954-.966.95.95 0 0 1 .955.966c0 .519-.444.967-.955.967Z"
                fill={fill}
                stroke={fill}
                strokeWidth=".111"
            />
        </svg>
        {Boolean(badge) && (
            <div className="rounded-full bg-black text-white grid place-content-center absolute top-0 right-0 translate-x-[5px] -translate-y-1 text-xs h-4 w-4 font-bold leading-120%">
                {badge}
            </div>
        )}
    </div>
)

export default CartIcon
