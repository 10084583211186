import { ILink } from 'interfaces/link'
import Link from 'next/link'
import * as React from 'react'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

interface IProps {
    links: ILink[]
    handleLinkClick?: () => void
}

const lgBreakPoint = screens.lg

export const NavigationIcons = ({ links, handleLinkClick }: IProps): React.ReactElement => {
    if (!links?.length) {
        return null
    }

    return (
        <ul className="flex">
            {links.map((link) => {
                const { href, picture } = link

                const { mobileImagesCollection, desktopImagesCollection } = picture ?? {}

                const mobileImage = mobileImagesCollection?.items?.[0]
                const desktopImage = desktopImagesCollection?.items?.[0]

                return (
                    <li key={link.sys?.id} className="block first:ml-0 ml-5">
                        <Link href={href} onClick={() => handleLinkClick?.()}>
                            {(mobileImage || desktopImage) && (
                                <picture>
                                    {desktopImage?.url && (
                                        <>
                                            {desktopImage.url.endsWith('.svg') && (
                                                <source
                                                    media={`(min-width: ${lgBreakPoint})`}
                                                    type="image/svg+xml"
                                                    srcSet={desktopImage.url}
                                                />
                                            )}
                                            <source
                                                media={`(min-width: ${lgBreakPoint})`}
                                                type="image/webp"
                                                srcSet={desktopImage.url + '?fm=webp&w=100'}
                                            />
                                            <source
                                                media={`(min-width: ${lgBreakPoint})`}
                                                type="image/jpeg"
                                                srcSet={desktopImage.url + '?fm=jpg&w=100'}
                                            />
                                        </>
                                    )}

                                    {mobileImage?.url && (
                                        <>
                                            {mobileImage.url.endsWith('.svg') && (
                                                <source type="image/svg+xml" srcSet={mobileImage.url} />
                                            )}
                                            <source type="image/webp" srcSet={mobileImage.url + '?fm=webp&w=100'} />
                                            <source type="image/jpeg" srcSet={mobileImage.url + '?fm=jpg&w=100'} />
                                        </>
                                    )}

                                    <img
                                        src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=100'}
                                        alt={
                                            mobileImage?.title ||
                                            desktopImage?.title ||
                                            mobileImage?.description ||
                                            desktopImage?.description
                                        }
                                        height={mobileImage?.height || desktopImage?.height}
                                        width={mobileImage?.width || desktopImage?.width}
                                    />
                                </picture>
                            )}
                        </Link>
                    </li>
                )
            })}
        </ul>
    )
}
