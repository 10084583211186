import * as React from 'react'
import TickMarkIcon from 'icons/tick-mark'
import styles from './style.module.css'

interface Props {
    label: React.ReactNode
    labelElement?: React.ReactNode
    className?: string
    labelClassName?: string
    checkMarkClassName?: string
    checkedBackgroundColor?: string
    checkedTickMarkColor?: string
}

const Checkbox = ({
    label,
    labelElement,
    className,
    labelClassName,
    checkMarkClassName,
    disabled,
    checkedBackgroundColor = '#fff',
    checkedTickMarkColor = '#000',
    ...props
}: Props & React.ComponentPropsWithoutRef<'input'>): React.ReactElement => {
    return (
        <label
            data-cy="checkbox-label"
            className={`flex flex-row-reverse items-start justify-end cursor-pointer rounded-full ${labelClassName} ${
                disabled ? 'text-gray cursor-not-allowed' : ''
            }`}
            style={{
                ['--checked-bg-color' as string]: checkedBackgroundColor,
            }}
        >
            {labelElement ? labelElement : label}
            <input
                type="checkbox"
                disabled={disabled}
                {...props}
                className={`${styles['custom-checkbox']} ${className ?? ''}`}
            />
            <div className={`${styles['checkmark']} ${checkMarkClassName ?? ''}`}>
                <TickMarkIcon stroke={checkedTickMarkColor} strokeWidth={2} />
            </div>
        </label>
    )
}

export default Checkbox
