import * as React from 'react'
import MinusIcon from 'icons/minus'
import PlusIcon from 'icons/plus'
import styles from './style.module.css'
import { IUiResources } from 'interfaces/ui-resource'
import { IGenericProductLineItem } from 'lib/cart'
import { getFormattedNumberWithCurrency } from 'lib/util/currency'
import { useOTCCartContext } from 'context/otc-cart-context'
import Link from 'next/link'
import { useCartSidebarContext } from 'context/cart-sidebar-context'
import { colors } from 'theme.cjs'
import CartLineItem from './cart-line-item'

interface Props {
    uiResources: IUiResources
    genericProductLineItem: IGenericProductLineItem
}

export function CartGenericProductLineItem({ uiResources, genericProductLineItem }: Props): React.ReactElement {
    const {
        addGenericProductQuantity,
        subtractGenericProductQuantity,
        removeGenericProductLineItem,
        isFetchingDetailsOfCartItems,
    } = useOTCCartContext()
    const { closeSidebar: closeCart } = useCartSidebarContext()

    const {
        variant: { price, compareAtPrice, availableForSale, title, id },
        canUpdateQuantity,
    } = genericProductLineItem

    return (
        <CartLineItem
            title={
                <>
                    <Link
                        href={`/products/${genericProductLineItem.slug}`}
                        className="text-sm font-bold block truncate"
                        onClick={() => closeCart()}
                    >
                        {genericProductLineItem.productName}
                    </Link>

                    <div className="text-gray text-xs mt-1.5">{title}</div>
                </>
            }
            price={Number(price.amount) * genericProductLineItem.quantity}
            currency={price.currencyCode}
            compareAtPrice={
                compareAtPrice?.amount
                    ? getFormattedNumberWithCurrency(Number(compareAtPrice?.amount), compareAtPrice?.currencyCode)
                    : null
            }
            image={genericProductLineItem.thumbnail}
            onRemove={() => removeGenericProductLineItem(id)}
            body={
                availableForSale &&
                canUpdateQuantity && (
                    <div className="py-0.5 px-3 border border-gray rounded-full flex justify-center">
                        <button
                            data-cy="subtract-quantity"
                            disabled={genericProductLineItem.quantity < 2}
                            className="disabled:opacity-20 text"
                            onClick={() => subtractGenericProductQuantity(1, id)}
                            aria-label={uiResources?.['ariaLabelSubtractQuantity']?.value as string}
                        >
                            <MinusIcon fill={colors.mineshaft} />
                        </button>
                        <span
                            data-cy="cart-item-quantity"
                            className={`mx-0.5 bg-transparent text-center text-xs flex items-center justify-center ${styles['cart-item-quantity']}`}
                        >
                            {genericProductLineItem.quantity}
                        </span>
                        <button
                            data-cy="add-quantity"
                            className="focus"
                            onClick={() => addGenericProductQuantity(1, id)}
                            aria-label={uiResources?.['ariaLabelAddQuantity']?.value as string}
                        >
                            <PlusIcon fill={colors.mineshaft} />
                        </button>
                    </div>
                )
            }
            loading={isFetchingDetailsOfCartItems}
            isAvailable={availableForSale}
        />
    )
}
