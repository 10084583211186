import isEmail from 'validator/lib/isEmail'
import isMobilePhone, { MobilePhoneLocale } from 'validator/lib/isMobilePhone'

const US_COUNTRY_CODE = '+1'

export const validateEmail = (email: string): boolean => {
    return isEmail(email + '')
}

export const prefixNumberWithCountryCode = (phone: string, locale: MobilePhoneLocale = 'en-US'): string => {
    if (phone.charAt(0) !== '+') {
        // To note, right now we are only supporting US phone number subscription
        if (locale === 'en-US') {
            return `${US_COUNTRY_CODE}${phone}`
        }
    }
    return phone
}

export const validateMobilePhone = (phone: string, locale: MobilePhoneLocale = 'en-US'): boolean => {
    const mobilePhone = prefixNumberWithCountryCode(phone, locale)
    return isMobilePhone(mobilePhone + '', locale)
}
