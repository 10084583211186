import * as React from 'react'
import Cart, {
    CART_VALIDITY_PERIOD_AFTER_CHECKOUT,
    CHECKOUT_TIMESTAMP_LS_KEY,
    IGenericProductLineItem,
    IKitLineItem,
    IMedicineLineItem,
    IShopifyCheckout,
} from 'lib/cart'
import useLocalStorageCart from 'hooks/useLocalStorage'
import { useUserContext } from './user-context'
import { useCustomerContext } from './customer-context'
import { gidToDetails } from 'lib/util/product'
import globalManifest from 'data/global-manifest.json'
import { ICoupon } from 'interfaces/coupon'
import { getCartCalculations } from 'hooks/useOTCCartCalculation'
import { CUSTOMER_KNOWN_EVENT_NAMES } from 'interfaces/customer'
import { getItemFromLS } from 'lib/util/storage'
import { useRouter } from 'next/router'

export interface IOTCCartContext extends Cart {
    cartLineItems: IMedicineLineItem[]
    kitLineItems: IKitLineItem[]
    isCheckingOut: boolean
    isFetchingDetailsOfCartItems: boolean
    isFetchingRemoteCheckout: boolean
    remoteCheckout: IShopifyCheckout
    genericProductLineItems: IGenericProductLineItem[]
    totalLineItems: number
}

interface IProps {
    children: React.ReactNode
}

const { rxProductPageSlugs } = globalManifest ?? {}

const OTCCartContext = React.createContext(null)

const OTCCartContextProvider = ({ children }: IProps): React.ReactElement => {
    const [cartLineItems, setCartLineItems] = React.useState<IMedicineLineItem[]>([])
    const [kitLineItems, setKitLineItems] = React.useState<IKitLineItem[]>([])
    const [remoteCheckout, setRemoteCheckout] = React.useState<IShopifyCheckout | null>(null)
    const [isFetchingRemoteCheckout, setIsFetchingRemoteCheckout] = React.useState(false)
    const [isCheckingOut, setIsCheckingOut] = React.useState(false)
    const [isFetchingDetailsOfCartItems, setIsFetchingDetailsOfCartItems] = React.useState(false)
    const [appliedCoupon, setAppliedCoupon] = React.useState<ICoupon>()
    const [genericProductLineItems, setGenericProductLineItems] = React.useState<IGenericProductLineItem[]>([])

    const cartInstanceRef = React.useRef<Cart>(null)

    const isMountedRef = React.useRef(false)

    // used to debounce remote cart/checkout request
    const updateRemoteCheckoutDebounceTimer = React.useRef<NodeJS.Timeout>()

    const {
        cartItems: localStorageCartItems,
        cartKitItems: localStorageKitItems,
        remoteCheckout: localStorageRemoteCheckout,
        coupon: localStorageCoupon,
        genericProductLineItems: localStorageGenericProductLineItems,
    } = useLocalStorageCart()

    const { user } = useUserContext()
    const { identity, track } = useCustomerContext()

    // Any time there's an update to the cart, let analytics know the state of the cart
    React.useEffect(() => {
        // Wait till cart updates complete before posting event
        // otherwise we're sending misleading cart values; ones that either don't show or barely show long
        // enough for a customer to see them
        const isFetchingAnything = isFetchingDetailsOfCartItems || isFetchingRemoteCheckout
        if (isFetchingAnything) return

        // Done fetching! Pull togeter all values of the cart
        const allCartItems = cartInstanceRef?.current?.allCartItemsAsLineItem
        const { discounts, subTotal, total, couponDiscount } = getCartCalculations({
            cartLineItems,
            kitLineItems,
            remoteCheckout,
            genericProductLineItems,
        })

        if (allCartItems) {
            // flatten + pull out amount of discounts
            const flatDiscounts = {}
            for (const key in discounts) {
                if (key?.length && typeof key === 'string') {
                    flatDiscounts[key.replace(/\s/g, '').toLowerCase()] = discounts[key]?.amount?.toFixed(2)
                }
            }
            // Note, this tracks raw updates to items in the cart, not aggregate line items like bundles
            const cartUpdate = {
                rawProductNames: allCartItems.map((item) => item?.productName),
                rawProductSkus: allCartItems.map((item) => item?.sku),
                rawProductVariantIds: allCartItems.map((item) => item?.variantId),
                shownProductNames: [
                    ...cartLineItems.map((item) => item.productName),
                    ...kitLineItems.map((kit) => kit.productName),
                ],
                cartSubTotal: subTotal,
                cartTotal: total,
                couponDiscount: couponDiscount.toFixed(2),
                ...flatDiscounts,
            }
            track('Cart - Update - State', cartUpdate)
        }
    }, [
        track,
        cartLineItems,
        kitLineItems,
        remoteCheckout,
        isFetchingRemoteCheckout,
        isFetchingDetailsOfCartItems,
        genericProductLineItems,
    ])

    React.useEffect(() => {
        // Safari caches the previous state when hitting back
        // This is problematic b/c we redirect to shopify for checkout and
        // if a user hits the browser back button, the checkout button is still in a loading state
        //  https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
        const onPageShow = (event) => {
            if (event.persisted) {
                setIsCheckingOut(false)
            }
        }
        window.addEventListener('pageshow', onPageShow)

        return () => {
            window.removeEventListener('pageshow', onPageShow)
        }
    }, [])

    // Instantiate the cart
    React.useEffect(() => {
        cartInstanceRef.current = new Cart()
    }, [])

    // When the user changes, update the cart's reference to that user
    React.useEffect(() => {
        if (cartInstanceRef.current) {
            cartInstanceRef.current.currentUser = user
        }
    }, [user])

    React.useEffect(() => {
        setRemoteCheckout(localStorageRemoteCheckout)
    }, [localStorageRemoteCheckout])

    // Call the Shopify backend and generate a new checkout with the current cart items
    const updateRemoteCheckout = React.useCallback(async () => {
        clearTimeout(updateRemoteCheckoutDebounceTimer.current)

        // wrap setTimeout inside promise so it can be awaited
        return new Promise<IShopifyCheckout>(
            (resolve) =>
                (updateRemoteCheckoutDebounceTimer.current = setTimeout(async () => {
                    setIsFetchingRemoteCheckout(true)
                    const remoteCheckout = await cartInstanceRef.current?.updateRemoteCheckout()
                    setRemoteCheckout(remoteCheckout)
                    setIsFetchingRemoteCheckout(false)

                    // resolve promise
                    resolve(remoteCheckout)
                }, 300)),
        )
    }, [])

    /**
     * If identified, set's the email on the cart (for abandonded checkout)
     *
     * 1) To note, this isn't always a 1:1 actions with user changes (above effect).
     * Sometimes a customer identifies themself without creating an account
     * 2) When we authenticate we set customer identity so we do not need to pull in data from auth user here
     */
    React.useEffect(() => {
        if (identity) {
            cartInstanceRef.current?.setIdentity(identity)
        }
    }, [identity])

    React.useEffect(() => {
        // update cart instance and state when local storage changes
        cartInstanceRef.current?.bulkUpdateCart(localStorageCartItems)
        cartInstanceRef.current?.bulkUpdateKit(localStorageKitItems)

        cartInstanceRef.current.bulkUpdateGenericProductsCart(localStorageGenericProductLineItems)

        // when the page/component loads for the first time
        if (!isMountedRef.current) {
            const cartCheckoutTimestamp = Number(getItemFromLS(CHECKOUT_TIMESTAMP_LS_KEY))

            if (!isNaN(cartCheckoutTimestamp) && cartCheckoutTimestamp !== 0) {
                const now = Date.now()

                const cartExpiresIn = cartCheckoutTimestamp + CART_VALIDITY_PERIOD_AFTER_CHECKOUT

                if (now > cartExpiresIn) {
                    cartInstanceRef.current?.clearCart()

                    isMountedRef.current = true

                    // exit this effect after clearing cart
                    return
                }
            }

            // refresh the details of cart items after they're fetched from local storage
            ;(async () => {
                try {
                    setIsFetchingDetailsOfCartItems(true)
                    await cartInstanceRef.current?.updatePricesAndInventory()

                    // update state with updated shopify details
                    setCartLineItems(cartInstanceRef.current?.lineItems)
                    setKitLineItems(cartInstanceRef.current?.kitLineItems)
                    setGenericProductLineItems(cartInstanceRef.current.genericProductLineItems)
                } catch (e) {
                    console.error(e)
                } finally {
                    setIsFetchingDetailsOfCartItems(false)
                }
            })()
            isMountedRef.current = true
        }

        // update state with updated cart tems
        setCartLineItems(cartInstanceRef.current?.lineItems)
        setKitLineItems(cartInstanceRef.current?.kitLineItems)
        setGenericProductLineItems(cartInstanceRef.current.genericProductLineItems)
    }, [localStorageCartItems, localStorageKitItems, localStorageGenericProductLineItems])

    // product line items
    const addLineItem = React.useCallback(
        (item: IMedicineLineItem) => {
            cartInstanceRef.current?.addLineItem(item)
            setCartLineItems(cartInstanceRef.current?.lineItems)
            updateRemoteCheckout()

            // Send AddToCart to marketing trackers
            if (item.id) {
                const productVariant = item.variants.find((variant) => variant.id === item.variantId)
                // convert GID
                const details = gidToDetails(item.id)
                if (details.type === 'Product') {
                    track(CUSTOMER_KNOWN_EVENT_NAMES.ADD_TO_CART, {
                        content_ids: [details.id],
                        content_name: rxProductPageSlugs.includes(item.slug) ? '****' : item.productName,
                        value: productVariant.price.amount,
                        currency: productVariant.price.currencyCode,
                    })
                }
            }
        },
        [updateRemoteCheckout, track],
    )

    const addQuantity = React.useCallback(
        (quantity: number, variantId: string) => {
            cartInstanceRef.current?.addQuantity(quantity, variantId)
            setCartLineItems(cartInstanceRef.current.lineItems)
            updateRemoteCheckout()
        },
        [updateRemoteCheckout],
    )

    const subtractQuantity = React.useCallback(
        (quantity: number, variantId: string) => {
            cartInstanceRef.current?.subtractQuantity(quantity, variantId)
            setCartLineItems(cartInstanceRef.current?.lineItems)
            updateRemoteCheckout()
        },
        [updateRemoteCheckout],
    )

    const removeLineItem = React.useCallback(
        (variantId: string) => {
            cartInstanceRef.current?.removeLineItem(variantId)
            setCartLineItems(cartInstanceRef.current?.lineItems)
            updateRemoteCheckout()
        },
        [updateRemoteCheckout],
    )

    const checkout = React.useCallback(() => {
        setIsCheckingOut(true)
        cartInstanceRef.current?.checkout()
    }, [])

    // kit line items
    const addKitLineItem = React.useCallback(
        (item: IKitLineItem) => {
            cartInstanceRef.current?.addKitLineItem(item)
            setKitLineItems(cartInstanceRef.current?.kitLineItems)
            updateRemoteCheckout()

            // Sending marketing event
            // 1. Map out Shopify product IDs
            const productIds = item.products.map((product) => {
                const details = gidToDetails(product.shopifyProduct.id)
                if (details.type === 'Product') {
                    return details.id
                } else {
                    return 'unknown'
                }
            })

            // 2. Send to trackers
            if (productIds.length) {
                const currency = item.products[0]?.shopifyProduct.variants.edges[0]?.node.price.currencyCode ?? 'USD'
                track(CUSTOMER_KNOWN_EVENT_NAMES.ADD_TO_CART, {
                    content_ids: productIds,
                    content_name: item.productName,
                    value: item.price,
                    currency,
                })
            }
        },
        [updateRemoteCheckout, track],
    )

    const removeKitLineItem = React.useCallback(
        (kitId: string) => {
            cartInstanceRef.current?.removeKitLineItem(kitId)
            setKitLineItems(cartInstanceRef.current?.kitLineItems)
            updateRemoteCheckout()
        },
        [updateRemoteCheckout],
    )

    const addKitQuantity = React.useCallback(
        (quantity: number, kitId: string) => {
            cartInstanceRef.current?.addKitQuantity(quantity, kitId)
            setKitLineItems(cartInstanceRef.current?.kitLineItems)
            updateRemoteCheckout()
        },
        [updateRemoteCheckout],
    )

    const subtractKitQuantity = React.useCallback(
        (quantity: number, kitId: string) => {
            cartInstanceRef.current?.subtractKitQuantity(quantity, kitId)
            setKitLineItems(cartInstanceRef.current?.kitLineItems)
            updateRemoteCheckout()
        },
        [updateRemoteCheckout],
    )

    const setCoupon = React.useCallback(
        async (coupon: ICoupon) => {
            if (!coupon) {
                cartInstanceRef.current?.setCoupon(null)
                setAppliedCoupon(null)
                return
            }

            cartInstanceRef.current?.setCoupon(coupon)
            setAppliedCoupon(coupon)

            // Update the checkout and cart after the coupon has been applied
            updateRemoteCheckout()
        },
        [updateRemoteCheckout],
    )

    const addGenericProductLineItem = React.useCallback(
        (item: IGenericProductLineItem) => {
            cartInstanceRef.current.addGenericProductLineItem(item)
            setGenericProductLineItems(cartInstanceRef.current.genericProductLineItems)
            updateRemoteCheckout()
        },
        [updateRemoteCheckout],
    )

    const addGenericProductQuantity = React.useCallback(
        (quantity: number, variantId: string) => {
            cartInstanceRef.current.addGenericProductQuantity(quantity, variantId)
            setGenericProductLineItems(cartInstanceRef.current.genericProductLineItems)
            updateRemoteCheckout()
        },
        [updateRemoteCheckout],
    )

    const subtractGenericProductQuantity = React.useCallback(
        (quantity: number, variantId: string) => {
            cartInstanceRef.current?.subtractGenericProductQuantity(quantity, variantId)
            setGenericProductLineItems(cartInstanceRef.current.genericProductLineItems)
            updateRemoteCheckout()
        },
        [updateRemoteCheckout],
    )

    const removeGenericProductLineItem = React.useCallback(
        (variantId: string) => {
            cartInstanceRef.current.removeGenericProductLineItem(variantId)
            setGenericProductLineItems(cartInstanceRef.current.genericProductLineItems)
            updateRemoteCheckout()
        },
        [updateRemoteCheckout],
    )

    const {
        query: { discount_code: discountCode },
    } = useRouter()

    React.useEffect(() => {
        let code = ''

        if (typeof discountCode === 'string') {
            code = discountCode
        } else if (Array.isArray(discountCode)) {
            code = discountCode[0]
        } else {
            code = localStorageCoupon?.discountCode
        }

        if (code) {
            setCoupon({ discountCode: code, label: code, name: code, value: undefined })
        }
    }, [localStorageCoupon, setCoupon, discountCode])

    const totalLineItems = cartLineItems.length + kitLineItems.length + genericProductLineItems.length

    const value = React.useMemo(
        () => ({
            addLineItem,
            addQuantity,
            subtractQuantity,
            removeLineItem,
            cartLineItems,
            checkout,
            isCheckingOut,
            isFetchingDetailsOfCartItems,
            // kit
            addKitLineItem,
            kitLineItems,
            removeKitLineItem,
            addKitQuantity,
            subtractKitQuantity,
            remoteCheckout,
            isFetchingRemoteCheckout,
            coupon: appliedCoupon,
            setCoupon,
            // generic product
            genericProductLineItems,
            addGenericProductLineItem,
            addGenericProductQuantity,
            subtractGenericProductQuantity,
            removeGenericProductLineItem,
            // cart info
            totalLineItems,
        }),
        [
            addLineItem,
            addQuantity,
            subtractQuantity,
            cartLineItems,
            checkout,
            removeLineItem,
            isCheckingOut,
            isFetchingDetailsOfCartItems,
            addKitLineItem,
            kitLineItems,
            removeKitLineItem,
            addKitQuantity,
            subtractKitQuantity,
            remoteCheckout,
            isFetchingRemoteCheckout,
            appliedCoupon,
            setCoupon,
            genericProductLineItems,
            addGenericProductLineItem,
            addGenericProductQuantity,
            subtractGenericProductQuantity,
            removeGenericProductLineItem,
            totalLineItems,
        ],
    )

    return <OTCCartContext.Provider value={value}>{children}</OTCCartContext.Provider>
}

export const useOTCCartContext = (): IOTCCartContext => {
    const otcCartContext = React.useContext(OTCCartContext)

    if (otcCartContext === undefined) {
        throw new Error('You cannot use useOTCCartContext outside of OTCCartContextProvider')
    }

    return otcCartContext
}

export default OTCCartContextProvider
