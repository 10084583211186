import * as React from 'react'
import globalManifest from 'data/global-manifest.json'
import { INavigation } from 'interfaces/navigation'
import { useAuth } from 'components/auth/auth'
import Button from 'components/buttons/button'
import { useSecondaryNavSidebarContext } from 'context/secondary-nav-sidebar-context'
import Link from 'next/link'
import NavigationBlocks from 'components/navigation/navigation-blocks'
import NavigationButtons from 'components/navigation/navigation-buttons'
import NavigationHorizontalList from 'components/navigation/navigation-horizontal-list'
import NavigationIcons from 'components/navigation/navigation-icons'
import NavigationVerticalList from 'components/navigation/navigation-vertical-list'
import { useRouter } from 'next/router'
import {
    helpNavigationMobileMegaMenuInternalName,
    profileToggleNavigationMobileMenuInternalName,
} from 'constants/contentful'
import { accountPagePath } from 'constants/url'
import { ProfileSelector } from 'components/profile-selector'

const {
    resources: globalUIResources,
    megaMenuNavigation: {
        mobileMegaMenu: {
            data: {
                navigationParentCollection: { items: mobileNavigationItems },
            },
        },
    },
} = globalManifest

const Navigation = ({ navigation }: { navigation: INavigation }): React.ReactElement => {
    const { isAuthenticated, loginWithRedirect, loading } = useAuth()
    const { closeSidebar } = useSecondaryNavSidebarContext()

    const links = navigation?.linksCollection?.items

    const handleLinkClick = React.useCallback(() => {
        closeSidebar()
    }, [closeSidebar])

    if (!links?.length) {
        return null
    }

    switch (navigation.renderType) {
        case 'blocks':
            return (
                <div className="pt-2.5">
                    <NavigationBlocks handleLinkClick={handleLinkClick} links={links} />
                </div>
            )

        case 'button':
            return <NavigationButtons handleLinkClick={handleLinkClick} links={links} />

        case 'vertical list':
            return <NavigationVerticalList handleLinkClick={handleLinkClick} links={links} />

        case 'horizontal list':
            return <NavigationHorizontalList handleLinkClick={handleLinkClick} links={links} />

        case 'icons':
            return (
                <div className="flex justify-between items-center">
                    <NavigationIcons handleLinkClick={handleLinkClick} links={links} />
                    {isAuthenticated ? (
                        <Link href="/account" onClick={handleLinkClick} className="button button-primary">
                            {globalUIResources['myCabinetButtonLabel_loggedOut'].value}
                        </Link>
                    ) : (
                        <Button
                            loading={loading}
                            onClick={() => loginWithRedirect({ appState: { returnTo: accountPagePath } })}
                            className="min-w-[6rem]"
                        >
                            {globalUIResources['logInButton'].value}
                        </Button>
                    )}
                </div>
            )

        default:
            return null
    }
}

export function MobileMegaMenu(): React.ReactElement {
    const { pathname } = useRouter()

    return (
        <div className="px-5 py-6.5 h-full overflow-auto">
            {/* sidebar body */}
            {mobileNavigationItems.length > 0 && (
                <nav className="grid gap-y-6">
                    {mobileNavigationItems.map((item) => {
                        return item.subNavigationsCollection.items.map((nav, index) => {
                            // help menu only to be shown inside account portal
                            if (
                                nav.internalName === helpNavigationMobileMegaMenuInternalName &&
                                pathname !== accountPagePath
                            ) {
                                return null
                            }

                            if (nav.internalName === profileToggleNavigationMobileMenuInternalName) {
                                return (
                                    <>
                                        <ProfileSelector key={nav.sys.id} />
                                        <Divider />
                                    </>
                                )
                            }

                            return (
                                <React.Fragment key={nav.sys.id}>
                                    <div key={nav.sys.id}>
                                        {nav.name && (
                                            <div className="text-xsm text-gray font-bold tracking-wide">{nav.name}</div>
                                        )}
                                        {nav.linksCollection.items.length > 0 && (
                                            <Navigation navigation={nav as INavigation} />
                                        )}
                                    </div>
                                    {index !== item.subNavigationsCollection.items.length - 1 && <Divider />}
                                </React.Fragment>
                            )
                        })
                    })}
                </nav>
            )}
        </div>
    )
}

function Divider() {
    return <hr className="border-t border-gray-medium -mx-5" />
}
