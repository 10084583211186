import * as React from 'react'
import { LOADING } from 'enum/loading'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { notAllowedErrorName } from 'misc-variables'
import * as Sentry from '@sentry/nextjs'
import { IconButton } from 'components/buttons/button'
import PlayIcon from 'icons/play-icon'
import { colors } from 'theme.cjs'
import { IUiResource } from 'interfaces/ui-resource'

interface IProps {
    children: React.ReactNode
    loading?: LOADING
    playMediaLabel?: IUiResource
    className?: string
}

const intersectioObserverOptions: IntersectionObserverInit = {
    rootMargin: '100px', // sets intersection point ahead by 100px
}

/**
 * Works exactly like HTML5 video element. Takes an
 * additional loading prop which can be used to lazy
 * load video. Loading defaults to lazy.
 * @param props IProps
 * @returns React.ReactElement
 */
const Video = (
    { children, loading = LOADING.LAZY, autoPlay, playMediaLabel, ...props }: IProps & React.ComponentProps<'video'>,
    forwardedRef,
): React.ReactElement => {
    const [videoEl, setVideoEl] = React.useState<HTMLVideoElement>()
    const [canAutoPlay, setCanAutoPlay] = React.useState<boolean>(null)

    // use useCallback to run whenever ref is assigned
    const videoElRef = React.useCallback((node) => {
        if (node !== null) {
            setVideoEl(node)
        }
    }, [])

    // expose glider methods to parent component
    React.useImperativeHandle(forwardedRef, () => videoEl)

    const { entry, observer } = useIntersectionObserver({
        element: videoEl,
        options: intersectioObserverOptions,
    })

    const isIntersecting = entry?.isIntersecting

    React.useEffect(() => {
        if (!videoEl) {
            return
        }

        // ready to load video
        if (isIntersecting || loading === LOADING.EAGER) {
            videoEl.load()

            // call play to check for browsers
            // that might be blocking autoplay
            // in such case show play button
            if (autoPlay) {
                const playPromise = videoEl.play()

                // older browsers don't return a promise
                if (typeof playPromise !== 'undefined') {
                    playPromise.catch((e) => {
                        if (e.name === notAllowedErrorName) {
                            setCanAutoPlay(false)
                        } else {
                            Sentry.captureException(e)
                        }
                    })
                }
            }
        }
    }, [isIntersecting, loading, entry, autoPlay, videoEl])

    React.useEffect(() => {
        // disconnect observer once the video has been requested
        if (isIntersecting || loading === LOADING.EAGER) {
            observer?.disconnect()
        }
    }, [observer, isIntersecting, loading])

    const deferLoading = loading === LOADING.LAZY && !isIntersecting

    const handlePlayButtonClick = () => {
        const playPromise = videoEl.play()

        // older browsers don't return promise
        if (typeof playPromise !== 'undefined') {
            playPromise
                .then(() => {
                    // autoplay is allowed after user interaction
                    setCanAutoPlay(true)
                    /**
                     * would the user want a pause button if we're
                     * showing them play button? The purpose of play
                     * button is for user interaction, after user has
                     * interacted with the site video autoplays don't
                     * throw error
                     */
                })
                .catch((e) => {
                    Sentry.captureException(e)
                })
        }
    }

    return (
        <div className="relative h-full">
            <video ref={videoElRef} autoPlay={autoPlay} {...props}>
                {
                    deferLoading
                        ? React.Children.map(children, (child) => {
                              if (React.isValidElement(child) && child.type === 'source') {
                                  // set src to null to prevent requesting video
                                  return React.cloneElement(child as React.ReactElement<any>, { src: null })
                              }
                              return child
                          })
                        : children // just return children when video needs to be loaded
                }
            </video>

            {/* play button */}
            {autoPlay && canAutoPlay === false && (
                <IconButton
                    onClick={handlePlayButtonClick}
                    className={`
                    w-14 h-14 lg:w-28 lg:h-28
                    rounded-full border-[3px] lg:border-[5px] border-white
                    flex justify-center items-center
                    absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20
                `}
                    label={playMediaLabel.value as string}
                >
                    <PlayIcon className="lg:scale-150" fill={colors.white} />
                </IconButton>
            )}
        </div>
    )
}

export default React.forwardRef(Video)
