/* eslint-disable react/display-name */
import * as React from 'react'
import { IconButton } from 'components/buttons/button'
import { useOTCCartContext } from 'context/otc-cart-context'
import { ICoupon } from 'interfaces/coupon'
import globalManifest from 'data/global-manifest.json'
import { getFormattedDate } from 'lib/util/date'
import PlusIcon from 'icons/plus'
import { colors } from 'theme.cjs'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

interface IProps {
    coupon: ICoupon
}

const lgBreakPoint = screens.lg

const { resources: globalResources, coupon: couponStaticData } = globalManifest ?? {}

const { header, mediaCollection, mobileMediaCollection, backgroundColor, paragraphCopy } = couponStaticData ?? {}

const mobileImage = mobileMediaCollection?.items?.[0]
const desktopImage = mediaCollection?.items?.[0]

const paragraphRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="text-xs leading-140% ">{children}</p>,
}

const Coupon = ({ coupon }: IProps): React.ReactElement => {
    const { setCoupon, coupon: couponInCart } = useOTCCartContext()

    const couponIsAddedToCart = Boolean(couponInCart)

    if (!coupon || couponIsAddedToCart) {
        return null
    }

    const titleRenderNode = {
        [BLOCKS.PARAGRAPH]: (_node, children) => (
            <span className="text-sm font-bold leading-140%">
                {React.Children.map(children, (child) => {
                    if (typeof child === 'string') {
                        return child.replace('{discount_percent}', coupon.value?.toString())
                    }

                    return child
                })}
            </span>
        ),
    }

    const currentTime = new Date()

    const dateString = getFormattedDate(
        currentTime,
        {
            dateStyle: 'short',
        },
        undefined,
        false,
    )

    return (
        <div
            className="px-2.5 py-2 h-full rounded-5 flex items-center justify-between"
            style={{ background: backgroundColor }}
        >
            <div className="flex items-center">
                {(mobileImage?.url || desktopImage?.url) && (
                    <picture className="shrink-0">
                        {desktopImage?.url && (
                            <>
                                {/* svg */}
                                {desktopImage.url.endsWith('.svg') && (
                                    <source
                                        media={`(min-width: ${lgBreakPoint})`}
                                        type="image/svg+xml"
                                        srcSet={desktopImage.url}
                                    />
                                )}
                                {/* webp */}
                                <source
                                    media={`(min-width: ${lgBreakPoint})`}
                                    type="image/webp"
                                    srcSet={desktopImage.url + '?fm=webp&w=200'}
                                />
                                {/* jpeg */}
                                <source
                                    media={`(min-width: ${lgBreakPoint})`}
                                    type="image/jpeg"
                                    srcSet={desktopImage.url + '?fm=jpg&w=200'}
                                />
                            </>
                        )}

                        {mobileImage?.url && (
                            <>
                                {/* svg */}
                                {mobileImage.url.endsWith('.svg') && (
                                    <source type="image/svg+xml" srcSet={mobileImage.url} />
                                )}

                                {/* webp */}
                                <source type="image/webp" srcSet={mobileImage.url + '?fm=webp&w=200'} />

                                {/* jpeg */}
                                <source type="image/jpeg" srcSet={mobileImage.url + '?fm=jpg&w=200'} />
                            </>
                        )}

                        <img
                            src={(mobileImage.url || desktopImage.url) + '?fm=jpg&w=200'}
                            className="h-20 w-20 object-cover shrink-0"
                            height={mobileImage.height || desktopImage.height}
                            width={mobileImage.width || mobileImage.width}
                            alt={
                                mobileImage.description ||
                                mobileImage.description ||
                                desktopImage.title ||
                                desktopImage.description
                            }
                            loading="lazy"
                        />
                    </picture>
                )}

                <div className={`flex flex-col ${mobileImage?.url || desktopImage?.url ? 'ml-2.5' : ''}`}>
                    <ContentfulRichText
                        className="coupon-title"
                        // @ts-ignore
                        content={header?.json}
                        renderNode={titleRenderNode}
                    />
                    <div className="mt-1">
                        <ContentfulRichText
                            className="coupon-paragraph"
                            // @ts-ignore
                            content={paragraphCopy?.json}
                            renderNode={paragraphRenderNode}
                        />
                    </div>
                    <span className="text-xs mt-1">
                        <span className="font-bold">{globalResources?.['cartCouponExpireLabel']?.value} </span>
                        <time dateTime={currentTime.toLocaleDateString()}>{dateString}</time>
                    </span>
                </div>
            </div>

            <IconButton
                label={globalResources?.['addCouponIconLabel']?.value}
                onClick={() => setCoupon(coupon)}
                className="h-9 w-9 flex justify-center items-center shrink-0 bg-white border ml-[5px]"
            >
                <PlusIcon fill={colors.mineshaft} />
            </IconButton>
        </div>
    )
}

export default Coupon
