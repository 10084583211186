import * as React from 'react'
import { Cart } from './cart'
import Sidebar from 'components/sidebar'
import { useCartSidebarContext } from 'context/cart-sidebar-context'
import { IUiResources } from 'interfaces/ui-resource'
import globalManifest from 'data/global-manifest.json'

interface Props {
    uiResources: IUiResources
}

const { resources: globalUIResources } = globalManifest

const CartSidebar = ({ uiResources }: Props): React.ReactElement => {
    const { open, closeSidebar: closeCart } = useCartSidebarContext()

    return (
        <Sidebar
            open={open}
            onClose={closeCart}
            uniqueSidebarIdentifier="cart"
            showTitleDivider
            title={globalUIResources['cartSidebarTitle'].value}
        >
            <Cart uiResources={uiResources} />
        </Sidebar>
    )
}

export default CartSidebar
