import * as React from 'react'
import Loader from 'components/loading/loader'

export interface IProps {
    children: React.ReactNode
    loading?: boolean
    className?: string
    secondary?: boolean
    small?: boolean
    loaderClassName?: string
}

interface IIconButtonProps extends IProps {
    label: string
}

/**
 * Renders an HTML button with either loading indicator
 * or children styled according to variant provided
 */
const Button = ({
    small,
    children,
    loading,
    className,
    secondary,
    loaderClassName = '',
    ...props
}: IProps & React.ComponentProps<'button'>): React.ReactElement => (
    <button
        className={`button ${secondary ? 'button-secondary' : 'button-primary'} ${
            loading ? 'opacity-60 flex justify-center items-center' : ''
        } ${className ?? ''} ${small ? 'button-sm' : ''}`}
        {...props}
    >
        {loading ? <Loader secondary={secondary} className={loaderClassName} /> : children}
    </button>
)

/**
 * Button with transparent background and underline
 * @param props Props
 * @returns
 */
export const PlainButton = ({
    children,
    className,
    ...props
}: IProps & React.ComponentProps<'button'>): React.ReactElement => {
    return (
        <button className={`button button-plain ${className ?? ''}`} {...props}>
            {children}
        </button>
    )
}

/**
 * Button with transparent background and underline
 * @param props Props
 * @returns
 */
export const IconButton = ({
    children,
    className,
    label,
    ...props
}: IIconButtonProps & React.ComponentProps<'button'>): React.ReactElement => {
    return (
        <button aria-label={label} className={`button button-icon ${className ?? ''}`} {...props}>
            {children}
        </button>
    )
}

export default Button
