import * as React from 'react'
import Link from 'next/link'
import { MyCabinetButton } from 'components/buttons/my-cabinet'
import { IconButton } from 'components/buttons/button'
import Logo from 'components/logo'
import { useCartSidebarContext } from 'context/cart-sidebar-context'
import { useOTCCartContext } from 'context/otc-cart-context'
import CartIcon from 'icons/cart-icon'
import { useSecondaryNavSidebarContext } from 'context/secondary-nav-sidebar-context'
import HamburgerIcon from 'icons/hamburger'
import LogoWithTradeMark from 'components/logo-with-trademark'
import { useRouter } from 'next/router'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { colors } from 'theme.cjs'
import Banner from 'components/banner/banner'
import { useBannerContext } from 'context/banner-context'
import globalManifest from 'data/global-manifest.json'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'
import DesktopMegaMenu from './desktop-mega-menu'
import { useRXCartContext } from 'context/rx-cart-context'
import { useHelpSidebar } from 'context/help-sidebar-context'
import { accountPagePath, homePagePath, blogCategoryHomePagePath, blogCategorySubPagePath } from 'constants/url'

const { resources, sitePagesWithTransparentNavbar, useTransparentHeaderInHomePage } = globalManifest

const pathsWithDynamicHeader = [blogCategoryHomePagePath, blogCategorySubPagePath]

if (useTransparentHeaderInHomePage) {
    pathsWithDynamicHeader.push(homePagePath)
}

const routesWithDynamicHeader = []
const routesWithDynamicHeaderJustMobile = []

sitePagesWithTransparentNavbar.forEach((page) => {
    const pagePath = /pages/ + page.slug
    // if len = 2, then both mobile and desktop
    if (page.useTransparentNavigationIn.length === 2) {
        routesWithDynamicHeader.push(pagePath)
    } else if (page.useTransparentNavigationIn.length === 1) {
        routesWithDynamicHeaderJustMobile.push(pagePath)
    }
})

function HeaderNav(): React.ReactElement {
    const { toggleSidebar: toggleCart } = useCartSidebarContext()
    const { toggleSidebar: toggleSecondaryNav } = useSecondaryNavSidebarContext()
    const { totalLineItems: totalOTCLineItems } = useOTCCartContext()
    const { totalLineItem: totalRXLineItems } = useRXCartContext()
    const { open: openHelpSidebar } = useHelpSidebar()

    const { showBanner, setHasBannerExitedScreen, banners } = useBannerContext()

    const [markerEl, setMarkerEl] = React.useState<HTMLDivElement>()

    const markerElRef = React.useCallback((node) => {
        if (node !== null) {
            setMarkerEl(node)
        }
    }, [])

    const headerElRef = React.useRef<HTMLHeadingElement>()

    const { pathname, asPath } = useRouter()

    const { entry: markerElEntry } = useIntersectionObserver({ element: markerEl })

    const asPathWithoutHash = asPath.split('#')[0].split('?')[0]

    const dynamicHeader =
        pathsWithDynamicHeader.includes(pathname) || routesWithDynamicHeader.includes(asPathWithoutHash)
    const dynamicHeaderJustMobile = routesWithDynamicHeaderJustMobile.includes(asPathWithoutHash)

    const banner = pathname === accountPagePath ? banners?.[1] ?? banners?.[0] : banners?.[0]

    const stickyBanner = banner?.stickyBanner

    const isBannerPresent = showBanner && banner

    let hasBannerExitedScreen = false

    // render page on server with transparent header
    if (typeof window === 'undefined') {
        hasBannerExitedScreen = false
    } else {
        if (!stickyBanner) {
            hasBannerExitedScreen = markerElEntry?.boundingClientRect?.top < 0
        }
    }

    const isMobileScreen = useMobileScreenDetection()

    React.useEffect(() => {
        setHasBannerExitedScreen(hasBannerExitedScreen)
    }, [hasBannerExitedScreen, setHasBannerExitedScreen])

    // fix header when in dynamic header routes and banner off screen
    let fixedHeader = false

    if (isBannerPresent || hasBannerExitedScreen) {
        if (dynamicHeader) {
            fixedHeader = true
        } else if (dynamicHeaderJustMobile && isMobileScreen) {
            fixedHeader = true
        }
    }

    const [isHeaderHovered, setIsHeaderHovered] = React.useState(false)
    const [isDropdownDropped, setIsDropdownDropped] = React.useState(false)

    // transparent header when in dynamic header routes and banner is on screen
    let showTransparentHeader = dynamicHeader && !hasBannerExitedScreen

    if (!hasBannerExitedScreen) {
        if (dynamicHeader) {
            showTransparentHeader = true
        } else if (dynamicHeaderJustMobile && isMobileScreen) {
            showTransparentHeader = true
        }
    }

    // dark uses color black inside all
    // components in header (text, logo, icons)
    // light uses white
    let headerContentColor =
        pathname === blogCategoryHomePagePath || pathname === blogCategorySubPagePath ? 'dark' : 'light'

    let headerPositionClassname = ''

    if (showTransparentHeader) {
        headerPositionClassname = 'absolute'
    } else if (fixedHeader) {
        headerPositionClassname = 'fixed top-0'
    } else {
        headerPositionClassname = 'sticky top-0'
    }

    let headerBorderColor = 'border-gray-medium'

    if (showTransparentHeader) {
        if (headerContentColor === 'light') {
            headerBorderColor = 'border-white'
        } else {
            headerBorderColor = 'border-black'
        }
    }

    let headerBackgroundColor

    if (isHeaderHovered) {
        headerBackgroundColor = 'bg-white'
        headerContentColor = 'dark'
    } else if (showTransparentHeader) {
        headerBackgroundColor = 'bg-transparent'
    } else {
        headerBackgroundColor = 'bg-white'
        headerContentColor = 'dark'
    }

    return (
        <>
            {/* place non fixed banner outside header */}
            {!stickyBanner && isBannerPresent && <Banner banner={banner} />}

            {/* intersection observer ref div */}
            <div data-x="marker" ref={markerElRef} />
            <header
                onMouseEnter={() => setIsHeaderHovered(true)}
                onMouseLeave={() => setIsHeaderHovered(false)}
                ref={headerElRef}
                className={`${
                    stickyBanner && isBannerPresent
                        ? 'h-[calc(var(--pageHeaderHeigth)+var(--bannerHeight))]'
                        : 'h-[var(--pageHeaderHeight)]'
                } cabinet-container w-full z-50 ${headerPositionClassname}`}
            >
                {/* place fixed banner inside header */}
                {stickyBanner && isBannerPresent && <Banner banner={banner} />}

                <div className="relative">
                    <div
                        className={`transition-colors ${
                            showTransparentHeader ? 'px-5' : 'px-0'
                        } ${headerBackgroundColor}`}
                    >
                        <div
                            className={`flex items-center
                                h-[var(--pageHeaderHeight)]
                                ${headerBorderColor}
                                ${showTransparentHeader ? '' : 'px-5'}
                                ${isDropdownDropped ? '' : 'border-b'}
                                mx-auto box-content
                            `}
                        >
                            <div className="lg:flex-1 flex h-full">
                                <IconButton
                                    data-cy="toggle-secondary-nav-button"
                                    onClick={toggleSecondaryNav}
                                    label={resources?.secondaryNavTitle?.value}
                                    className="px-0 lg:hidden"
                                >
                                    <HamburgerIcon
                                        stroke={
                                            showTransparentHeader && headerContentColor === 'light'
                                                ? colors.white
                                                : colors.mineshaft
                                        }
                                    />
                                </IconButton>

                                <DesktopMegaMenu
                                    isNavbarTransparent={showTransparentHeader && headerContentColor === 'light'}
                                    setIsDropdownDropped={setIsDropdownDropped}
                                    isDropdownDropped={isDropdownDropped}
                                />
                            </div>
                            <Link
                                href="/"
                                aria-label={resources?.logoLinkLabel?.value}
                                data-cy="header-logo"
                                className="ml-[18px] flex-grow lg:ml-0 lg:flex-grow-0"
                            >
                                <div className="sm:hidden">
                                    <Logo
                                        fill={
                                            showTransparentHeader && headerContentColor === 'light'
                                                ? colors.white
                                                : colors.mineshaft
                                        }
                                    />
                                </div>
                                <div className="hidden sm:block">
                                    <LogoWithTradeMark
                                        white={showTransparentHeader && headerContentColor === 'light'}
                                    />
                                </div>
                            </Link>
                            <div className="flex-1 flex justify-end items-center h-full space-x-9 lg:space-x-6.5">
                                {pathname === accountPagePath && (
                                    <button className="font-bold hidden lg:block text-xbase" onClick={openHelpSidebar}>
                                        {resources['navbarHelpMenuLabel'].value}
                                    </button>
                                )}

                                <MyCabinetButton light={showTransparentHeader && headerContentColor === 'light'} />

                                <IconButton
                                    data-cy="toggle-cart-button"
                                    label={resources?.toggleCartIconButtonLabel?.value}
                                    className="ml-2 lg:ml-6 px-0"
                                    onClick={toggleCart}
                                >
                                    <CartIcon
                                        fill={
                                            showTransparentHeader && headerContentColor === 'light'
                                                ? colors.white
                                                : colors.mineshaft
                                        }
                                        badge={totalRXLineItems + totalOTCLineItems}
                                    />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* add a displacer element when there's sticky banner and header
             * is positioned absolute(transparent) or fixed
             */}
            {(showTransparentHeader || fixedHeader) && stickyBanner && isBannerPresent && (
                // shift main down by header height when header is absolute (transparentHeader)
                <div
                    className={`min-w-full ${stickyBanner ? 'h-[var(--bannerHeight)]' : 'h-[var(--pageHeaderHeight)]'}}
                    `}
                />
            )}
        </>
    )
}

export default HeaderNav
