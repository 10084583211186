import * as React from 'react'
import MinusIcon from 'icons/minus'
import PlusIcon from 'icons/plus'
import styles from './style.module.css'
import { IUiResources } from 'interfaces/ui-resource'
import { IMedicineLineItem } from 'lib/cart'
import { getFormattedNumberWithCurrency } from 'lib/util/currency'
import { useOTCCartContext } from 'context/otc-cart-context'
import Link from 'next/link'
import { useCartSidebarContext } from 'context/cart-sidebar-context'
import { FIRST_NUMBER_IDENTIFIER_REGEXP, JAR_PILL_VARIANT_REGEXP } from 'lib/util/sku-querying'
import { colors } from 'theme.cjs'
import CartLineItem from './cart-line-item'

interface Props {
    uiResources: IUiResources
    cartLineItem: IMedicineLineItem
}

export function CartMedicineLineItem({ uiResources, cartLineItem }: Props): React.ReactElement {
    const { addQuantity, subtractQuantity, removeLineItem, isFetchingDetailsOfCartItems } = useOTCCartContext()
    const { closeSidebar: closeCart } = useCartSidebarContext()

    const selectedVariant = cartLineItem?.variants?.find((variant) => variant.id === cartLineItem.variantId)

    const isJarVariant = JAR_PILL_VARIANT_REGEXP.test(selectedVariant?.sku)

    const totalProductPrice = Number(selectedVariant.price.amount) * cartLineItem.quantity
    const totalPillsInVariant = Number(FIRST_NUMBER_IDENTIFIER_REGEXP.exec(selectedVariant.sku)[0])

    return (
        <CartLineItem
            image={cartLineItem.thumbnail}
            title={
                <>
                    {cartLineItem.slug ? (
                        <Link
                            href={`/products/${cartLineItem.slug}`}
                            className="text-sm font-bold block truncate"
                            onClick={() => closeCart()}
                        >
                            {cartLineItem.productName}
                        </Link>
                    ) : (
                        <span className="text-sm font-bold block truncate">{cartLineItem.productName}</span>
                    )}
                    {isJarVariant && (
                        <div className="text-gray text-xs mt-1.5">
                            1 {uiResources['cartItemRefillableBottleLabel'].value}
                        </div>
                    )}
                    <div className="text-gray text-xs mt-1">
                        {totalPillsInVariant}{' '}
                        {totalPillsInVariant === 1 ? cartLineItem.pillType : cartLineItem.pillTypePlural}{' '}
                    </div>
                </>
            }
            price={totalProductPrice}
            currency={selectedVariant?.price?.currencyCode}
            compareAtPrice={
                selectedVariant?.compareAtPrice?.amount
                    ? getFormattedNumberWithCurrency(
                          Number(selectedVariant?.compareAtPrice?.amount),
                          selectedVariant?.compareAtPrice?.currencyCode,
                      )
                    : null
            }
            onRemove={() => removeLineItem(selectedVariant?.id)}
            loading={isFetchingDetailsOfCartItems}
            body={
                selectedVariant.availableForSale && (
                    <div className="py-0.5 px-3 border border-gray rounded-full flex justify-center">
                        <button
                            data-cy="subtract-quantity"
                            disabled={cartLineItem?.quantity < 2}
                            className="disabled:opacity-20 text"
                            onClick={() => subtractQuantity(1, selectedVariant?.id)}
                            aria-label={uiResources?.['ariaLabelSubtractQuantity']?.value as string}
                        >
                            <MinusIcon fill={colors.mineshaft} />
                        </button>
                        <span
                            data-cy="cart-item-quantity"
                            className={`mx-0.5 bg-transparent text-center text-xs flex items-center justify-center ${styles['cart-item-quantity']}`}
                        >
                            {cartLineItem?.quantity}
                        </span>
                        <button
                            data-cy="add-quantity"
                            className="focus"
                            onClick={() => addQuantity(1, selectedVariant?.id)}
                            aria-label={uiResources?.['ariaLabelAddQuantity']?.value as string}
                        >
                            <PlusIcon fill={colors.mineshaft} />
                        </button>
                    </div>
                )
            }
            isAvailable={selectedVariant.availableForSale}
        />
    )
}
