const colors = {
    mineshaft: '#3c3c3c',
    darkestGray: '#767676',
    slateGray: '#a8a8a8',
    mediumGray: '#d5d5d5',
    coolGray: '#ADC5CD',
    white: '#ffffff',
    wildsand: '#f2f2f2',
    coral: '#E99581',
    slateBlue: '#99B5D7',
    purple: '#D2C2E9',
    teal: '#9FC6CC',
    mustard: '#CAB667',
    successGreen: '#6FCF97',
    successGreenLight: '#97dbb2',
    green: '#6BA088',
    red: '#f74f51',
    redLight: '#fb7f80',
    urgentRed: '#FFAD9C',
    skyBlue: '#BDD4FD',
    highlightBlue: '#BFD3FF',
    rxBlue: '#DBEDF3',
    pink: '#FFD4CE',
    orange: '#F39F52',
    orangeLight: '#f6b881',
    yellow: '#FBF0A5',

    // colors of medicins pouch/packets
    sleepAndStress: '#AB6C92',
    allergyRelief: '#65B3BE',
    stomachRelief: '#f592A4',
    painRelief: '#6098CC',
    coldAndFlu: '#F39F52',

    allergyReliefDarker: '#65B3BE',
    allergyReliefLighter: '#EBF9F9',
    allergyReliefText: '#6BA089',

    painFeverLighter: '#C8D9F9',
}

const pouchRatio = 1.6
const defaultPouchWidth = 265

const pageBackgroundClassname = 'bg-gray-lighter'
const articleMaxWidth = '768px'

module.exports = {
    colors,
    pouchRatio,
    defaultPouchWidth,
    pageBackgroundClassname,
    articleMaxWidth,
}
