import { ICoupon } from 'interfaces/coupon'
import { wordInsideSquareBracketsRegexp, wordInsideCurlyBracketsRegexp, trailingHyphensRegexp } from './regexps'

export const COUPON_FEATURE_VARIATIONS = {
    control: 'control',
    coupon: 'coupon-only',
}

export const getCouponFromFeature = (featureVariationName: string): ICoupon => {
    // replace discount code and percentage: 'promo-name-[discount20]-{20}'
    const prompFeatureWithoutCouponAndDiscount = featureVariationName
        ?.replace(wordInsideSquareBracketsRegexp, '')
        ?.replace(wordInsideCurlyBracketsRegexp, '')
        ?.replace(trailingHyphensRegexp, '')

    const discountCode = wordInsideSquareBracketsRegexp
        .exec(featureVariationName ?? '')?.[0]
        ?.replace('[', '')
        ?.replace(']', '')

    const discountPercentage = wordInsideCurlyBracketsRegexp
        .exec(featureVariationName ?? '')?.[0]
        ?.replace('{', '')
        ?.replace('}', '')

    return {
        label: featureVariationName,
        name: prompFeatureWithoutCouponAndDiscount,
        value: Number(discountPercentage),
        discountCode,
    }
}
